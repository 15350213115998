import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import VueYandexMetrika from 'vue-yandex-metrika'
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VueYandexMetrika, {
  id: 35279310,
  router: router,
  env: process.env.NODE_ENV
  // other options
})