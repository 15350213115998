<template>
  <div>
    <v-row class="pt90 mb-8">
      <v-col class="col-12 mt-0 pt-0 mb-0 pb-0">
        <h2 class="v-text-h-2">Формирование рекомендаций</h2>
        <div class="v-text-body-1">Для всех типов пользователей происходит формирование индивидуальных рекомендаций (рекомендательная модель) по каждому виду контента: live-трансляция, запись эфира, VOD, OTT-сервисы.</div>
      </v-col>
    </v-row>
    <v-row class="pb90">
      <v-col class="col-12 col-md-6 mt-0 pt-0 mb-4 pb-0">
        <h4 class="v-text-h-4 mt-12 mb-8">В автоматических рекомендациях учитываются:</h4>
        <div class="v-text-body-1">
          <p>
            <ul>
              <li>Индивидуальная история просмотра передач/фильмов за последние 90 дней (список любимых и нелюбимых передач)</li>
              <li>Индивидуальная история незаконченного просмотра (список глубиной в 3 суток)</li>
            </ul>
          </p>
          <p>
            Чем выше вероятность присутствия конкретного типа пользователя перед экраном, тем выше число подходящих рекомендаций будет продемонстрировано
          </p>
        </div>
      </v-col>
      <v-col class="col-12 col-md-6 mt-0 pt-0 mb-4 pb-0 text-center d-flex flex-column">
        <v-img contain :src="require(`@/assets/telecom_recs.png`)" />
      </v-col>      
    </v-row>
  </div>
</template>

<style lang="scss">
</style>
<script>
export default {
};
</script>
