<template>
  <div class="home-offer-row">
    <v-row class="pt90 pb90">
      <v-col class="col-12 mt-0 pt-0 col-md-8">
        <h2 class="v-text-h-2 mb-4">Разработка на заказ</h2>
        <div class="v-text-body-1 mb-4">
          Также MediaHills предлагает услуги разработки на заказ в области телекоммуникаций, измерения телесмотрения и работы с большими данными, в том числе для оценки эффективности рекламных кампаний на ТВ.
        </div>
        <h3 class="v-text-h-3 mb-4">Мы можем предложить:</h3>
        <ul>
          <li>Одно из наших решений</li>
        </ul>
        <v-row>
          <v-col class="col-12 col-lg-4">
            <div class="offerBlock" @click="openPage('media')">
              <h4 class="v-text-h-4">Личный&nbsp;кабинет<br />mediahills</h4>
              <div class="v-text-body-1">телеканалам</div>
            </div>
          </v-col>
          <v-col class="col-12 col-lg-4">
            <div class="offerBlock" @click="openPage('adv')">
              <h4 class="v-text-h-4">
                эстим:<br />
                интерактивы
              </h4>
              <div class="v-text-body-1">рекламе</div>
            </div>
          </v-col>
          <v-col class="col-12 col-lg-4">
            <div class="offerBlock" @click="openPage('telecom')">
              <h4 class="v-text-h-4">баланс&nbsp;ТВ-пакета</h4>
              <div class="v-text-body-1">операторам связи</div>
            </div>
          </v-col>
        </v-row>
        <ul class="mb-4">
          <li>Подогнать наше решение под ваши нужды</li>
          <li>Разработать новый продукт по вашему ТЗ</li>
        </ul>
      </v-col>
      <v-col class="col-12 mt-0 pt-0 col-md-4">
        <contact-form />
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>

<style lang="scss">
.home-offer-row {
  background-color: #e9f1ff;
}
.offerBlock {
  padding: 16px;
  height: 140px;
  border-radius: 16px;
  background: linear-gradient(180deg, #ffcd1b 0%, #ffa91b 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  cursor: pointer;
}
</style>
<script>
import contactForm from "@/components/ContactForm";
import router from "@/router";

export default {
  components: { contactForm },
  methods: {
    async openPage(page) {
      await router.push(page);
    }
  }

};
</script>
