<template>
  <div class="footer-container">
    <v-row class="pt-9 pb-9">
      <v-col class="col-12 col-md-6 d-flex align-center">
        <div style="width: 100%">
          <div class="v-text-caption">АО «МЕДИА ХИЛС»</div>
          <v-row>
            <v-col class="col-12 col-sm-6">
              <v-icon color="indigo" class="mr-2 mt-n1">mdi-phone</v-icon>
              <a class="v-text-h-5 indigo--text" href="tel:+74957052933"
                >8 (499) 705-29-33</a
              >
            </v-col>
            <v-col class="col-12 col-sm-6">
              <v-icon color="indigo" class="mr-2 mt-n1">mdi-email</v-icon>
              <a
                class="v-text-h-5 indigo--text"
                href="mailto:info@mediahills.ru"
                >info@mediahills.ru</a
              >
            </v-col>
          </v-row>
          <div class="v-text-overline">
            <v-icon x-small color="blue" class="mt-n1">mdi-map-marker</v-icon>
            117246, город Москва, Научный проезд, дом 17 Бизнес-Центр «9 Акров»
          </div>
        </div>
      </v-col>
      <v-col class="col-12 col-md-6">
        <div class="v-text-caption b58 mb-4">ПРОДУКТЫ</div>
        <div class="v-text-overline mb-2">
          Личный кабинет «Медиа Хилс»
          <v-btn
            color="rgba(0, 72, 185, 0.1)"
            rounded
            x-small
            elevation="0"
            class="ml-4 contactBtn"
            @click="showContactForm('demo_lk')"
            >запросить демо</v-btn
          >
        </div>
        <div class="v-text-overline mb-2">
          <a href="https://balance.cableman.ru" target="_blank" class="blue--text">Баланс ТВ-пакета</a>
        </div>
        <div class="v-text-overline mb-4">
          Расчет: интерактивы
          <v-btn
            color="rgba(0, 72, 185, 0.1)"
            rounded
            x-small
            elevation="0"
            class="ml-4 contactBtn"
            @click="showContactForm('demo_estim')"
            >запросить демо</v-btn
          >
        </div>
        <div class="v-text-caption b58 mb-4">
          СВЯЖИТЕСЬ С НАМИ ПО ВОПРОСАМ ТЕХПОДДЕРЖКИ:
        </div>
        <div>
          <v-icon color="blue" class="mr-2" small>mdi-email</v-icon>
          <a
            class="v-text-overline blue--text"
            href="mailto:support@mediahills.ru"
            >support@mediahills.ru</a
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" scoped>
.footer-container {
  background-color: #e9f1ff;
}
</style>

<script>
export default {
  methods: {
    showContactForm(type) {
      this.$store.commit("showContactForm", type);
    }
  }
};
</script>
