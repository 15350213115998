<template>
  <div>
    <v-row class="pt90 mb-8">
      <v-col class="col-12 mt-0 pt-0 mb-0 pb-0">
        <h2 class="v-text-h-2">Внутренняя кухня</h2>
      </v-col>
    </v-row>
    <v-row class="pb90">
      <v-col class="col-12 col-md-4 mt-0 pt-0 mb-4 pb-0 justify-center d-flex">
        <div :style="getKitchenStyle" style="position: relative" id="kI">
          <v-img class="kitchenImage" :src="require('@/assets/data_source_scheme.png')" :width="700" :height="700"/>
          <v-menu absolute open-on-hover close-on-content-click :position-x="60" :position-y="425" attach="#kI">
            <template v-slot:activator="{ on, attrs }">
              <div class="kitchenHover1" v-bind="attrs" v-on="on"></div>
            </template>
            <img class="kitchenArrow" :src="require(`@/assets/arrow_1.png`)" />
          </v-menu>
          <v-menu absolute open-on-hover close-on-content-click :position-x="260" :position-y="350" attach="#kI">
            <template v-slot:activator="{ on, attrs }">
              <div class="kitchenHover2" v-bind="attrs" v-on="on"></div>
            </template>
            <img class="kitchenArrow" :src="require(`@/assets/arrow_2.png`)" />
          </v-menu>
          <v-menu absolute open-on-hover close-on-content-click :position-x="430" :position-y="410" attach="#kI">
            <template v-slot:activator="{ on, attrs }">
              <div class="kitchenHover3" v-bind="attrs" v-on="on"></div>
            </template>
            <img class="kitchenArrow" :src="require(`@/assets/arrow_3.png`)" />
          </v-menu>
        </div>
      </v-col>
      <v-col class="col-12 col-md-8 mt-0 pt-0 mb-4 pb-0 v-text-body-2">
        MediaHills собирает информацию с помощью партнеров и операторов связи о
        поведении абонентов и характеристиках домохозяйств
        <v-row class="mt-8">
          <v-col class="col-12 col-md-6 ml-0 pl-0">
            <h4 class="v-text-h-4 purple--text mb-2">
              Операторы связи
            </h4>
            <div class="v-text-body-1">
              Интернет-интересы,
              геоданные, состав семей и их характеристики
            </div>
          </v-col>  
          <v-col class="col-12 col-md-6 ml-0 pl-0">
            <h4 class="v-text-h-4 purple--text mb-2">
              wifi снифферы
            </h4>
            <div class="v-text-body-1">
              Геоданные
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col class="col-12 col-md-6 ml-0 pl-0">
            <h4 class="v-text-h-4 purple--text mb-2">
              Магазины, ОФД
            </h4>
            <div class="v-text-body-1">
              Магазины, ОФД
              Покупки
            </div>
          </v-col>  
          <v-col class="col-12 col-md-6 ml-0 pl-0">
            <h4 class="v-text-h-4 purple--text mb-2">
              Интернет-панели
            </h4>
            <div class="v-text-body-1">
              Состав семей и их характеристики, иные данные опросов
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
  .kitcenImage {
    position: absolute;
    top: 0; 
    left: 0; 
  }
 #kI .v-menu__content {
    box-shadow: none !important;
  }
  .kitchenHover3 {
    cursor: pointer;
    position: absolute;
/*     left: 76.19%;
    right: 3.85%;
    top: 61.75%;
    bottom: 12.1%; */
    left: 65.19%;
    right: 13.85%;
    top: 61.75%;
    bottom: 5.1%;    

    transform: rotate(38.31deg);
    //border: 1px solid red;
  }
  .kitchenHover2 {
    cursor: pointer;
    position: absolute;
/*     left: 63.05%;
    right: 22.34%; */
    left: 47%;
    right: 32%;
    top: 53.29%;
    bottom: 23%;
    transform: rotate(38.96deg);
    //border: 1px solid red;
  }
  .kitchenHover1 {
    cursor: pointer;
    position: absolute;
    left: 14.57%;
    right: 61.14%;
    top: 53.29%;
    bottom: 12.57%;
    //border: 1px solid red;
  }
</style>
<script>
export default {
  computed: {
    getKitchenStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "zoom: 0.45";
        case 'sm': return "zoom: 0.55";
        case 'md': return "zoom: 0.65";
        case 'lg': return "zoom: 0.75";
        case 'xl': return "zoom: 0.9";
      }

      return ""
    }
  }
};
</script>
