<template>
  <v-container class="home">
    <title-block />
    <subtitle-block />
    <channels-block />
    <demo-block />
    <footer-block />
  </v-container>
</template>
<style>
</style>
<script>
import TitleBlock from "@/components/MediaTitleBlock";
import SubtitleBlock from "@/components/MediaSubtitleBlock";
import ChannelsBlock from "@/components/MediaChannelsBlock";
import DemoBlock from "@/components/MediaDemoBlock";
import FooterBlock from "@/components/Footer";

export default {
  name: "Home",
  components: { TitleBlock, SubtitleBlock, ChannelsBlock, DemoBlock, FooterBlock },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
</style>
