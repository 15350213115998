import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/es5/locale/ru";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#0048B9",
        yellow: "#FFA91B",
        indigo: "#002563",
        blue: "#0048B9",
        purple: "#A30067",
        green: "#00952C",
        orange: "#FF6600",
        red: "#DA0010",
      },
    },
  },
});
