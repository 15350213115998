import axios from "axios";

class BackService {
  getNews(user) {
    return axios.post("/site/get_news", user).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }

  sendContactForm(data) {
    return axios.post("/site/send_form", data).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }
}

export default new BackService();
