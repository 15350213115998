<template>
  <div class="about-method-row-1">
    <v-row class="pt90 pb120">
      <v-col class="col-12">
          <div class="v-text-body-1 mb-4 secondary--text">
          <a
            class="secondary--text text-decoration-underline"
            @click="openPage('/about')"
            >О нас</a
          >
          <v-icon class="ml-4 mr-4" color="secondary">mdi-chevron-right</v-icon>
          <a
            class="secondary--text text-decoration-underline"
            @click="openPage('/about/technology')"
            >Как мы работаем</a
          >
          <v-icon class="ml-4 mr-4" color="secondary">mdi-chevron-right</v-icon>
          9 принципов GGTAM
        </div>
        <h1 class="v-text-h-1 mb-12">
            9 принципов GGTAM
        </h1>
        <div class="v-text-body-2">
          9 принципов, которые MediaHills сформулировал на основе международных правил проведения телеизмерений GGTAM
        </div>
      </v-col>
    </v-row> 
  </div>
</template>

<style lang="scss">
.about-method-row-1 {
  background-color: #E9F1FF;
}
</style>

<script>
import router from "@/router";
export default {
  methods: {
    async openPage(page) {
      await router.push(page)
    }
  }

};
</script>
