<template>
  <v-container class="home">
    <v-row class="mt-12 mb-6">
      <v-col class="col-12">
        <h1 class="v-text-h-1">Хронология событий</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div v-for="year in $store.getters.getNewsYears" :key="year.id">
          <div class="yearHeader mb-8" :class="`yearHeader_${year.id}`"  @click="toggleYearBlock(year)">
            <div class="yearHeaderLine">
            </div>
            <div class="yearHeaderContent d-flex justify-space-between">
              <div class="yearChip">
                {{year.year}}
              </div>
              <div class="yearChip">
                Статей: {{$store.getters.getNewsByYear(year.year, posted).length}}
                <v-icon x-small v-if="year.opened">mdi-menu-up</v-icon>
                <v-icon x-small v-if="!year.opened">mdi-menu-down</v-icon>
              </div>
            </div>
          </div>
          <div v-if="year.opened">
            <div v-for="news in $store.getters.getNewsByYear(year.year, posted)" :key="news.id" class="mb-8">
              <div class="newsHeader mb-8">
                <div class="newsHeaderLine">
                </div>
                <div class="newsHeaderContent d-flex">
                  <div class="dateNewsChip mr-4">
                    {{drawNewsDate(news)}}
                  </div>
                  <div class="yearNewsChip">
                    {{year.year}}
                  </div>
                  <div class="typeNewsChip ml-4" v-if="news.type">
                    {{news.type}}
                  </div>
                </div>
              </div>
              <div :class="news.type.toLowerCase()=='пресса о нас'?'pressAbout':''">
                <div v-if="news.type.toLowerCase()=='пресса о нас'" class="d-flex">
                  <div class="mb-4 secondary--text pressSourceChip" @click="openLink(news.link)">{{news.source}}</div>
                </div>
                <div class="mb-4">
                  <strong>{{news.title}}</strong>
                </div>
                <div v-html="news.content"></div>
                <div v-if="news.type.toLowerCase()=='пресса о нас'" 
                  class="d-flex secondary--text" 
                  @click="openLink(news.link)"
                  style="cursor: pointer"
                >
                  <v-icon class="mr-3 ml-0" color="secondary">mdi-open-in-new</v-icon>
                  <span style="text-decoration: underline;">Читать на сайте источника</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <footer-block />
  </v-container>
</template>
<style>
.yearHeader {
  position: relative;
  height: 32px;
}
.yearHeaderContent {
  width: 100%;
  font-family: "Noto Sans";
  position: absolute;
  top: 0;
  z-index: 1;
  line-height: 32px;
  height: 32px;
  font-size: 14px;
  font-style: normal;
}
.yearHeaderLine {
  position: absolute;
  top: 16px;
  height: 1px;
  width: 100%;
  background: #EEEEEE;
}
.yearChip {
  padding: 0px 12px;
  background: #EEEEEE;
  border-radius: 16px;
  color: rgba(0,0,0,.87);
}

.yearHeader_0 .yearHeaderLine {
  background: linear-gradient(272.93deg, #50D367 0%, #C6FF7E 100%);
}
.yearHeader_0 .yearChip{
  background: linear-gradient(272.93deg, #50D367 0%, #C6FF7E 100%);
}

.newsHeader {
  position: relative;
  height: 20px;
}

.newsHeaderContent {
  width: 100%;
  font-family: "Noto Sans";
  position: absolute;
  top: 0;
  z-index: 1;
  line-height: 32px;
  height: 32px;
  font-size: 10px;
  font-style: normal;
}
.newsHeaderLine {
  position: absolute;
  top: 16px;
  height: 1px;
  width: 100%;  
  background: rgba(0, 0, 0, 0.12);
}
.yearNewsChip {
  padding: 0px 12px;
  background: #EEEEEE;
  border-radius: 16px;
  color: rgba(0,0,0,.87);
  font-size: 14px;
}
.dateNewsChip {
  padding: 0px 12px;
  background: #0048B9;
  border-radius: 16px;
  font-size: 14px;
  color: white;
}
.typeNewsChip {
  padding: 0px 12px;
  background: linear-gradient(272.93deg, #50D367 0%, #C6FF7E 100%);
  border-radius: 16px;
  color: rgba(0,0,0,.87);
  font-size: 14px;
}
.pressAbout {
  background: rgba(0, 72, 185, 0.1);
  border: 2px solid #0048B9;
  border-radius: 0px 16px 16px 16px;
  padding: 16px;
}
.pressSourceChip {
  padding: 0px 12px;
  background: #FFFFFF;
  /* custom/secondary */
  border: 1px solid #0048B9;
  border-radius: 10px;  
}
</style>

<script>
import moment from "moment";
import FooterBlock from "@/components/Footer";

export default {
  name: "News",
  components: { FooterBlock },
  data() {
    return {
      posted: 1,
    };
  },
  mounted() {
    this.$store.dispatch("GetNews");
    this.posted = 1;
    if (this.$route.name=="news_") this.posted = 0;
  },
  computed: {
  },
  methods: {
    openLink(link) {
      window.open(link)
    },
    drawNewsDate(news) {
      return moment(news.posted_date, "YYYY-MM-DD HH:mm:ss").locale("ru").format("DD MMMM")
    },
    toggleYearBlock(year) {
      this.$store.commit("toggleYearBlock", {yearId: year.id})
    }
  },
  watch: {
    "$route.path": function () {
      this.posted = 1;
      if (this.$route.name=="news_") this.posted = 0;
     // console.log(this.posted)
    },
  },

};
</script>
<style lang="scss">
</style>
