<template>
  <div class="d-flex flex-column">
    <div>
      <v-row class="mb-0 pb90">
      <v-row v-if="$vuetify.breakpoint.smAndDown">
        <method-link-block />
      </v-row>        
        <v-col class="col-12 d-flex pt90">
          <div class="flex-grow-1">
            <h2 class="v-text-h-2 mb-12">
              Что мы анализируем
            </h2>
            <div class="mt-n4 ml-2">
              <div class="techChip mr-6 mt-4">Линейное ТВ</div>
              <div class="techChip mr-6 mt-4">ТВ-архив</div>
              <div class="techChip mr-6 mt-4">VOD</div>
              <div class="techChip mr-6 mt-4">Онлайн-кинотеатры</div>
              <div class="techChip mr-6 mt-4">Сайты, приложения</div>
            </div>
          </div>
          <method-link-block v-if="$vuetify.breakpoint.mdAndUp" />
        </v-col>
      </v-row>
      <v-row class="mw1300 d-flex justify-center">
          <h2 class="v-text-h-2 mb-12 text-center">
            Методология предусматривает следующие этапы обработки данных
          </h2>
          <div class="d-flex align-top">
            <div class="techTitle mr-12 mt-n2" v-if="$vuetify.breakpoint.name!='xs'">
              <h3 class="v-text-h-3">Сбор данных</h3>
              <div class="mt-2">
                <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (1 этап).pdf" style="text-decoration:none;">
                  <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                </a>
                <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (1 этап).pdf" class="ml-2 v-text-body-1 secondary--text text-decoration-underline">Подробнее об этапе</a>
              </div>
            </div>
            <div class="ml-12" v-if="$vuetify.breakpoint.name=='xs'">
            </div>
            <div class="techStage pl-10">
              <div class="stageNumber">1</div>
              <div class="v-text-body-1 mt-n10">
                  <h3 class="v-text-h-3 mb-4" v-if="$vuetify.breakpoint.name=='xs'">Сбор данных</h3>
                  <div class="mb-2" v-if="$vuetify.breakpoint.name=='xs'">
                    <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (1 этап).pdf" style="text-decoration:none;">
                      <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                    </a>
                    <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (1 этап).pdf" class="ml-2 v-text-body-1 secondary--text text-decoration-underline">Подробнее об этапе</a>
                  </div>
                  <p>Сбор данных с операторов связи (RPD)</p>
                  <p>Объединение с программой передач (EPG)</p>
                  <p>Интеграция данных операторов связи для каждого региона</p>
                </div>
            </div>
          </div>
          <div class="d-flex align-top">
            <div class="techTitle mr-12 mt-n2" v-if="$vuetify.breakpoint.name!='xs'">
              <h3 class="v-text-h-3">Фильтрация и очистка</h3>
              <div class="mt-2">
                <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (2 этап).pdf" style="text-decoration:none;">
                  <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                </a>
                <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (2 этап).pdf" class="ml-2 v-text-body-1 secondary--text text-decoration-underline">Подробнее об этапе</a>
              </div>              
            </div>
            <div class="ml-12" v-if="$vuetify.breakpoint.name=='xs'">
            </div> 
            <div class="techStage pl-10">
              <div class="stageNumber">2</div>
              <div class="v-text-body-1 mt-n10">
                  <h3 class="v-text-h-3 mb-4" v-if="$vuetify.breakpoint.name=='xs'">Фильтрация и очистка</h3>
                  <div class="mb-2" v-if="$vuetify.breakpoint.name=='xs'">
                    <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (2 этап).pdf" style="text-decoration:none;">
                      <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                    </a>
                    <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (2 этап).pdf" class="ml-2 v-text-body-1 secondary--text text-decoration-underline">Подробнее об этапе</a>
                  </div>
                  <p>Поиск и удаление неисправных источников данных</p>
                  <p>Выявление и фильтрация данных с не&nbsp;выключенных приставок</p>
                  <p>Мониторинг</p>
                </div>
            </div>
          </div>
          <div class="d-flex align-top">
            <div class="techTitle mr-12 mt-n2" v-if="$vuetify.breakpoint.name!='xs'">
              <h3 class="v-text-h-3">Формирование панели</h3>
              <div class="mt-2">
                <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (3 этап).pdf" style="text-decoration:none;">
                  <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                </a>
                <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (3 этап).pdf" class="ml-2 v-text-body-1 secondary--text text-decoration-underline">Подробнее об этапе</a>
              </div>
            </div>
            <div class="ml-12" v-if="$vuetify.breakpoint.name=='xs'">
            </div> 
            <div class="techStage pl-10">
              <div class="stageNumber">3</div>
              <div class="v-text-body-1 mt-n10">
                  <h3 class="v-text-h-3 mb-4" v-if="$vuetify.breakpoint.name=='xs'">Формирование панели</h3>
                  <div class="mb-2" v-if="$vuetify.breakpoint.name=='xs'">
                    <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (3 этап).pdf" style="text-decoration:none;">
                      <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                    </a>
                    <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (3 этап).pdf" class="ml-2 v-text-body-1 secondary--text text-decoration-underline">Подробнее об этапе</a>
                  </div>                  
                  <p>Проведение установочных исследований по всем измеряемым регионам</p>
                  <p>Формирование репрезентативной панели на их основе</p>
                </div>
            </div>
          </div>
          <div class="d-flex align-top">
            <div class="techTitle mr-12 mt-n2" v-if="$vuetify.breakpoint.name!='xs'">
              <h3 class="v-text-h-3">Восстановление демографии</h3>
              <div class="mt-2">
                <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (4 этап).pdf" style="text-decoration:none;">
                  <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                </a>
                <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (4 этап).pdf" class="ml-2 v-text-body-1 secondary--text text-decoration-underline">Подробнее об этапе</a>
              </div>
            </div>
            <div class="ml-12" v-if="$vuetify.breakpoint.name=='xs'">
            </div> 
            <div class="techStage pl-10" style="border: none">
              <div class="stageNumber">4</div>
              <div class="v-text-body-1 mt-n10">
                  <h3 class="v-text-h-3 mb-4" v-if="$vuetify.breakpoint.name=='xs'">Восстановление демографии</h3>
                  <div class="mb-2" v-if="$vuetify.breakpoint.name=='xs'">
                    <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (4 этап).pdf" style="text-decoration:none;">
                      <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                    </a>
                    <a download href="/documents/MediaHills - Этапы обработки данных о телесмотрении (4 этап).pdf" class="ml-2 v-text-body-1 secondary--text text-decoration-underline">Подробнее об этапе</a>
                  </div>
                  <p>Обучение нейронной сети на основе собственных операторов связи и панели Mediascope</p>
                  <p>Восстановление индивидуального телесмотрения (переход от ТВ-приставки к телезрителям)</p>
                </div>
            </div>
          </div>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss">
  .stageNumber {
    margin-left: -60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;

    width: 40px;
    height: 40px;

    /* indigo/indigo */

    background: #002563;
    border-radius: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: white;
  }
  .techTitle {
    width: 50%;
    text-align: right;
  }
  .techStage {
    border-left: 1px dashed black;
    width: 50%;
	  @media screen and (max-width: 600px) {
        width: auto;
        padding-right: 8px;
		}  
  }
  .techChip {
    display: inline-block;
    flex-direction: row;
    align-items: center;
    padding: 0 6px 0 6px;
    height: 40px;
    line-height: 40px;
    /* green/green */
    background: rgba(0,0,0,.12);
    color: rgba(0,0,0,.87);
    border-radius: 4px;
  }
</style>

<script>
import router from "@/router";
import MethodLinkBlock from "@/components/MethodLinkBlock";
export default {
  components: {MethodLinkBlock},
  methods: {
    async openPage(page) {
      await router.push(page)
    }
  }

};
</script>
