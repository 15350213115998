import Vue from "vue";
import VueRouter from "vue-router";
import Media from "@/views/Media.vue";
import Home from "@/views/Home.vue";
import Adv from "@/views/Adv.vue";
import Telecom from "@/views/Telecom.vue";
import News from "@/views/News.vue";
import About from "@/views/About.vue";
import AboutTech from "@/views/AboutTech.vue";
import AboutMethod from "@/views/AboutMethod.vue";
import Contacts from "@/views/Contacts.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "Home",
    component: Home,
  },
  {
    path: "/media",
    name: "media",
    component: Media,
  },
  {
    path: "/adv",
    name: "adv",
    component: Adv,
  },
  {
    path: "/telecom",
    name: "telecom",
    component: Telecom,
  },
  {
    path: "/news",
    name: "news",
    component: News,
  },
  {
    path: "/news_",
    name: "news_",
    component: News,
  },
  {
    path: "/about/technology",
    name: "aboutTech",
    component: AboutTech,
  },
  {
    path: "/about/guidelines",
    name: "aboutMethod",
    component: AboutMethod,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash, top: -20 }
    } else {
      return { x: 0, y: 0 }
    }
  },  
});

export default router;
