<template>
  <div class="d-flex flex-column">
    <div class="adv-title-row-1">
      <v-row class="pt90">
        <v-col class="col-12">
          <div class="v-text-body-1 mb-4 text-center text-sm-left" style="color: rgba(0, 37, 99, 0.6)">
            Для рекламодателей 
              <br v-if="$vuetify.breakpoint.xs">
            и рекламных агентств
          </div>
          <h1 class="v-text-h-1 mb-12 text-center text-sm-left">
            Готовим данные для <span class="green--text">основательного анализа</span> эффективности 
            <span v-if="!$vuetify.breakpoint.xs">рекламных кампаний на&nbsp;ТВ</span>
            <span v-if="$vuetify.breakpoint.xs">РК&nbsp;на&nbsp;ТВ</span>
          </h1>
          <v-row class="mb120 d-flex flex-column flex-sm-row">
            <v-btn
              color="rgba(0, 149, 44, 1)"
              class="contactBtn mr-4 mb-4 mb-sm-0"
              @click="showContactForm('contact')"
              >Написать нам</v-btn
            >
            <v-btn color="secondary" text outlined class="blueBorderBtn mr-4" @click="makeCall">
              <v-icon small>mdi-phone</v-icon>
              +7 (499) 705-29-33
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="adv-title-row-2 mt-0 pt-0">
      <v-row>
        <v-col class="col-12" style="padding-top: 90px">
          <h2 class="v-text-h-2 mb-12 white--text">Узнайте с нашей помощью</h2>
          <div class="v-text-body-1 mb-12 white--text">
            Не каждый рекламодатель или рекламное агентство знает импакт своего
            труда на рынке. Мы можем предоставить реальные данные для подготовки
            исследований, которые помогут вам лучше понять эффективность
            проведения рекламных кампаний на ТВ, оценить оптимальные сроки
            проведения РК и набор телеканалов для размещения, лучше узнать
            ТВ-предпочтения и интернет-активности целевых аудиторий
          </div>
        </v-col>
      </v-row>
      <v-row class="pb90 pl-2 pr-2 pr-md-0 pl-md-0">
        <v-col class="col-12 col-md-6 creditsLink white--text mr-0 pr-0" v-if="$vuetify.breakpoint.smAndUp">
          <div
            v-for="item in credits"
            :key="item.id"
            class="creditLinkContainer mb-6"
          >
            <div class="creditLinkBgr" v-if="creditId == item.id"></div>
            <div class="creditLinkTitle pr-2">
              <h4
                class="credit-h-4"
                @click="creditId = item.id"
                :class="creditId == item.id ? 'yellow--text' : ''"
              >
                {{item.title}}
              </h4>
            </div>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6 ml-0 pl-0">
          <div class="creditBlock d-flex flex-column justify-space-between">
            <div class="d-flex flex-column justify-space-between">
              <div v-if="!$vuetify.breakpoint.smAndUp" class="d-flex flex-row justify-space-between align-center mb-8">
                <v-icon x-large class="white--text" style="height: 40px" @click="prevCreditCard">mdi-arrow-left</v-icon>
                <h4 class="v-text-h-4 white--text text-center pl-2 pr-2" v-text="currentCredit.title" />
                <v-icon x-large class="white--text" style="height: 40px" @click="nextCreditCard">mdi-arrow-right</v-icon>
              </div>
              <h4 v-if="$vuetify.breakpoint.smAndUp" class="v-text-h-4 white--text mt-0 mb-6" v-text="currentCredit.title" />
              <div class="d-flex flex-column-reverse flex-md-row justify-space-between align-center text-center align-sm-start text-sm-left ">
                <div class="pr-0 pr-sm-8 mt-4 mt-sm-0">
                  <div class="v-text-subtitle-1 mb-4 mt-4 mt-sm-0 white--text">Описание</div>
                  <div class="v-text-body-1 white--text">{{currentCredit.description}}</div>
                  <div v-if="currentCredit.description2" class="v-text-subtitle-1 mt-3 white--text">{{currentCredit.description2}}</div>
                </div> 
                <div :style="$vuetify.breakpoint.smAndUp?'':'width: 100%'">
                  <div class="v-text-subtitle-1 mb-4 white--text">Обмен&nbsp;данными</div>
                  <div class="d-flex flex-row justify-center">
                    <div v-if="currentCredit.data_excel" class="v-text-body-1 white--text creditExcel">Excel</div>
                    <div v-if="currentCredit.data_api" class="v-text-body-1 white--text ml-2 creditApi">АПИ</div>
                  </div>
                </div> 
              </div>
            </div>
            <v-btn
              class="blueBorderBtn"
              :style="$vuetify.breakpoint.smAndUp ? 'max-width: 180px' : ''"
              @click="showContactForm(currentCredit.title)"
              >Мне интересно</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss">
.adv-title-row-1 {
  background: url("../assets/waves2.png") rgba(45, 204, 93, 0.2) no-repeat
    center bottom !important;
  // background-size: 100%!important;
  overflow-x: hidden;
}

.adv-title-row-2 {
  background-color: #004916;
}

.creditBlock {
  box-sizing: border-box;
/*   display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: flex-start; */
  padding: 32px;
  gap: 24px;
  background: #004916;
  /* yellow */
  border: 2px solid #ffa91b;
  box-shadow: 10px 10px 0px #ff9801;
  border-radius: 16px;
  height: 100%;
}

.creditLinkContainer {
  position: relative;
  height: 36px;
}
.creditsLink {
  cursor: pointer;
}
.creditsLink .active {
}
.creditLinkBgr {
  position: absolute;
  top: 18px;
  height: 1px;
  width: 100%;
  /* yellow */
  background: #ffa91b;
  z-index: 0;
}
.creditLinkTitle {
  height: 36px;
  position: absolute;
  top: 0;
  z-index: 1;
  background: #004916 !important;
}
.creditExcel {
  padding: 4px 8px;
  gap: 4px;
  height: 42px;
  /* green */
  background: #00952C;
  border-radius: 8px;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
}
.creditApi {
  padding: 4px 8px;
  gap: 4px;
  height: 42px;
  /* red */
  background: #DA0010;
  border-radius: 8px;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;  
}
</style>

<script>
export default {
  data: () => ({
    creditId: 1,
    credits: [
      {
        id: 1,
        title: "«Сырые данные» по ЦА",
        description: "Сессии просмотров по заданной ЦА",
        description2: "(телезритель-город-телеканал-начало-окончание)",
        data_excel: true,
        data_api: true,
      },
      {
        id: 2,
        title: "Поминутные аудиторные показатели по ЦА",
        description:
          "Аудиторные показатели по 250+ городам и всей РФ, города миллионники, города «100 тысяч плюс», города «100 тысяч минус»",
        description2: "",
        data_excel: true,
        data_api: true,
      },
      {
        id: 3,
        title: "Post Buy Analysis",
        description:
          "Аудитория каждого ролика, набранные TRP, охват, частота контактов",
        description2: "",
        data_excel: true,
        data_api: true,
      },
      {
        id: 4,
        title: "Brand-lift",
        description:
          "Аналитика по запросам, оценка эффективности проведения РК брендов, лонч продуктов",
        description2: "",
        data_excel: true,
        data_api: false,
      },
      {
        id: 5,
        title: "Sales-lift",
        description:
          "Аналитика по запросам, оценка эффективности проведения РК, мэтчинг телекома с ОФД",
        description2: "",
        data_excel: true,
        data_api: false,
      },
      {
        id: 6,
        title: "Traffic-lift",
        description: "Аналитика по запросам",
        description2: "",
        data_excel: true,
        data_api: false,
      },
      {
        id: 7,
        title: "AD HOC аналитика",
        description:
          "Аналитика по запросам, оценка эффективности проведения РК",
        description2: "",
        data_excel: true,
        data_api: false,
      },
    ],
  }),
  methods: {
    makeCall() {
      window.open("tel:+74997052933");
    },
    showContactForm(type) {
      this.$store.commit("showContactForm", type);
    },
    nextCreditCard() {
      this.creditId++
      if (this.creditId>this.credits.length) this.creditId = 1;
    },
    prevCreditCard() {
      this.creditId--
      if (this.creditId<1) this.creditId = this.credits.length;
    }
  },
  computed: {
    currentCredit() {
      const item = this.credits.find(item=>item.id==this.creditId)
      return item;
    }
  },
  watch: {
    creditLink: function () {
      console.log(this.creditLink);
    },
  },
};
</script>
