<template>
  <div class="telecom-contact">
    <v-row class="pt90 mb-4">
      <v-col class="col-12 mt-0 pt-0 mb-0 pb-0">
        <h2 class="v-text-h-2 white--text text-center">Свяжитесь с нами</h2>
      </v-col>
    </v-row>
    <v-row class="pb90 d-flex justify-center flex-column flex-sm-row">
      <v-btn
        color="rgba(0, 149, 44, 1)"
        class="contactBtn mr-4 mb-4 mb-sm-0 ml-4"
        @click="showContactForm('contact')"
        >Написать нам</v-btn
      >
      <v-btn color="white" text outlined class="blueBorderBtn2 mr-4 ml-4"  @click="makeCall">
        <v-icon small>mdi-phone</v-icon>
        +7 (499) 705-29-33
      </v-btn>
    </v-row>  

  </div>
</template>

<style lang="scss">
.telecom-contact {
  background-color: #0048B9;
}
</style>
<script>
export default {
  methods: {
    makeCall() {
      window.open("tel:+74997052933");
    },
    showContactForm(type) {
      this.$store.commit("showContactForm", type);
    }
  }
};
</script>
