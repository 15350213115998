<template>
  <div class="d-flex flex-column media-channels">
    <div class="media-channels-row-1">
      <v-row style="padding-top: 90px">
        <v-col class="col-12">
          <h2 class="v-text-h-2 d-flex justify-center white--text">
            Работаем со всеми типами ТВ-каналов
          </h2>
          <div class="v-text-body-1 d-flex justify-center mt-8 white--text">
            Выберите свой тип, чтобы посмотреть специальное предложение
          </div>
          <div class="d-flex justify-center mt-8 mb-11">
            <v-row>
              <v-col class="col-12 col-md-4">
                <v-btn :color="channelsBlock == 1 ?'#A30067':'#FFFFFF'" class="white--text" block @click="channelsBlock = 1">
                  <span class="v-text-h-4 mr-1" :style="channelsBlock == 1 ? 'color:#FFFFFF':'color:#000000'"
                    >Региональным</span
                  >
                  <span class="v-text-h-4" :style="channelsBlock == 1 ? 'color:#FFFFFF':'color:#A30067'">каналам</span>
                </v-btn>
              </v-col>
              <v-col class="col-12 col-md-4">
                <v-btn :color="channelsBlock == 2 ?'#00952C':'#FFFFFF'" class="white--text" block @click="channelsBlock = 2">
                  <span class="v-text-h-4 mr-1" :style="channelsBlock == 2 ? 'color:#000000':'color:#000000'"
                    >Тематическим</span
                  >
                  <span class="v-text-h-4" :style="channelsBlock == 2 ? 'color:#000000':'color:#00952C'">каналам</span>
                </v-btn>
              </v-col>
              <v-col class="col-12 col-md-4">
                <v-btn :color="channelsBlock == 3 ?'#0048B9':'#FFFFFF'" class="white--text" block @click="channelsBlock = 3">
                  <span class="v-text-h-4 mr-1" :style="channelsBlock == 3 ? 'color:#FFFFFF':'color:#000000'"
                    >Федеральным</span
                  >
                  <span class="v-text-h-4" :style="channelsBlock == 3 ? 'color:#FFFFFF':'color:#0048B9'">каналам</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="media-channels-1" v-if="channelsBlock==1">
      <v-row class="pt90">
        <v-col class="col-12 col-md-6">
          <h3 class="v-text-h-3 white--text mb-4">
            Анализ пересечения посетителей сайта с телезрителями
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              Сбор данных с операторов связи помогает оценить пересечение
              аудитории телеканала и аудитории собственного сайта
            </p>
            <p>Для чего это нужно?</p>
            <ul>
              <li>
                Анализ смотрения новостных выпусков на ТВ и просмотров
                аналогичных новостей на сайте канала
              </li>
              <li>
                Мониторинг трансляций выступлений представителей органов власти
                и оценка привлекательности поднятых тем для телезрителей
              </li>
            </ul>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6">
          <v-img :src="require('@/assets/media_reg_1.png')" />
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center order-last order-md-first">
          <v-img :src="require('@/assets/media_reg_2.png')" />
        </v-col>
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <h3 class="v-text-h-3 white--text mb-4">
            Поминутная аналитика новостных выпусков
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              Просматривайте запись эфира* в моменты наибольшего притока или
              оттока зрителей, чтобы оценивать влияние сюжетов на аудиторию
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <h3 class="v-text-h-3 white--text mb-4">
            Оценка эффективности программирования
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              Наша система окрасит блок телепередачи в зеленый или красный цвет
              относительно того, насколько хорошие показатели у нее были
            </p>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <v-img :src="require('@/assets/media_reg_3.png')" />
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center order-last order-md-first">
          <v-img :src="require('@/assets/media_reg_4.png')" />
        </v-col>
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <h3 class="v-text-h-3 white--text mb-4">
            Оценка эффективности собственного и закупного контента
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              Сравнивайте телепередачи на своем телеканале по основным
              показателям
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <h3 class="v-text-h-3 white--text mb-4">Сравнение с конкурентами</h3>
          <div class="v-text-body-1 white--text">
            <p>
              Система позволяет сравнить как общие показатели телеканалов, так и
              эффективность программ
            </p>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <v-img :src="require('@/assets/media_fed_3.png')" />
        </v-col>
      </v-row>
      <v-row class="mt-12 pb90">
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center order-last order-md-first">
          <v-img :src="require('@/assets/media_tem_2.png')" />
        </v-col>
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <h3 class="v-text-h-3 white--text mb-4">
            Индивидуальные аналитические отчеты
          </h3>
          <div class="v-text-body-1 white--text">
            <ul>
              <li>Общая динамика телеканала</li>
              <li>Бенчмаркинг (положение телеканала на рынке)</li>
              <li>
                Анализ эффективности рекламных кампаний (пре- и посткампейн)
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="media-channels-2" v-if="channelsBlock==2">
      <v-row class="pt90">
        <v-col class="col-12 col-md-6 order-last order-md-first">
          <v-img :src="require('@/assets/media_tem_1.png')" />
        </v-col>        
        <v-col class="col-12 col-md-6">
          <h3 class="v-text-h-3 white--text mb-4">
            Сравнение с конкурентами
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              Система позволяет сравнить общие показатели телеканалов, работать над программированием и контрпрограммированием телеканала
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <h3 class="v-text-h-3 white--text mb-4">
            Индивидуальные аналитические отчеты
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              <ul>
                <li>Общая динамика телеканала</li>
                <li>Бенчмаркинг (положение телеканала на рынке)</li>
                <li>Анализ эффективности рекламных кампаний (пре- и посткампейн)</li>
              </ul>
            </p>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <v-img :src="require('@/assets/media_tem_2.png')" />
        </v-col>        
      </v-row>
      <v-row class="mt-12 pb90">
        <v-col class="col-12 col-md-6 order-last order-md-first">
          <v-img :src="require('@/assets/media_tem_3.png')" />
        </v-col>        
        <v-col class="col-12 col-md-6">
          <h3 class="v-text-h-3 white--text mb-4">
            Основательный анализ контента собственного производства
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              <ul>
                <li>Аудиторные показатели телепрограмм</li>
                <li>Конкурентный анализ</li>
                <li>Оценка сюжетов</li>
                <li>Аудитория по географии</li>
                <li>Поток зрителей (приходы и уходы) в реальном времени с возможностью просмотра эфира*</li>
              </ul>
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="media-channels-3" v-if="channelsBlock==3">
      <v-row class="pt90">
        <v-col class="col-12 col-md-6">
          <h3 class="v-text-h-3 white--text mb-4">
            Аналитика в реальном времени с возможностью просмотра эфира
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              Просматривайте запись эфира* в моменты наибольшего притока или оттока зрителей, чтобы оценивать влияние сюжетов на аудиторию
            </p>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6">
          <v-img :src="require('@/assets/media_fed_1.png')" />
        </v-col>        
      </v-row>
      <v-row class="mt-12">
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center order-last order-md-first">
          <v-img :src="require('@/assets/media_fed_2.png')" />
        </v-col>            
        <v-col class="col-12 col-md-6 d-flex flex-column justify-center">
          <h3 class="v-text-h-3 white--text mb-4">
            Производителям контента
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              <ul>
                <li>Общая динамика собственных передач и передач конкурентов</li>
                <li>Поминутный поток телезрителей, оценка сюжетов</li>
                <li>Оценка эффективности спикеров</li>
                <li>Интерес к поднятым темам по сравнению с темами на конкурирующих каналах с поминутной раскладкой</li>
              </ul>
            </p>
          </div>
        </v-col>    
      </v-row>
      <v-row class="mt-12 pb90">
        <v-col class="col-12 col-md-6">
          <h3 class="v-text-h-3 white--text mb-4">
            Конкурентный анализ
          </h3>
          <div class="v-text-body-1 white--text">
            <p>
              Система позволяет сравнить как общие показатели телеканалов, так и эффективность программ
            </p>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6">
          <v-img :src="require('@/assets/media_fed_3.png')" />
        </v-col>        
      </v-row>
    </div>
  </div>
</template>

<style lang="scss">
.media-channels {
  background-color: #002563;
}
.media-channels-1 {
  background: linear-gradient(180deg, #002563 0%, #a30067 100%);
}
.media-channels-2 {
  background: linear-gradient(180deg, #002563 0%, #11685e 50.52%, #00952c 100%);
}
.media-channels-3 {
  background: linear-gradient(180deg, #002563 0%, #0048b9 100%);
}
</style>
<script>
export default {
  data: () => ({
    channelsBlock: 1,
  }),
};
</script>
