<template>
<div class="d-flex flex-column">
  <div class="tech-products-row-1">
    <v-row class="pt-12 tech-products-bg mw1300">
        <v-col class="col-12">
            <div class="v-text-body-2 text-center mb-12">
              Обработанные данные используются<br>для построения различных отчетов в наших продуктах
            </div>
        </v-col>
    </v-row>   
  </div>
  <v-row class="pb90 mw1300">
    <v-col class="col-12 col-lg-4">
        <div
          class="productBlock d-flex flex-column flex-sm-row flex-lg-column align-center align-sm-start justify-space-between"
          @mouseover="setActiveProduct(1)"
          @mouseleave="setActiveProduct('')"
          :class="{'active': (activeProduct==1), 'inactive': (activeProduct!=1)}"
        >
          <v-img :src="require('@/assets/about_product_1.png')" width="100%" contain v-if="$vuetify.breakpoint.lgAndUp"/>
          <div>
            <h4 class="v-text-h-4 secondary--text mb-4 text-center text-sm-left text-lg-center text-xs-center">
              <a href="https://estim-int.mediahills.ru" target="_blank">estim-int.mediahills.ru</a>
            </h4>
            <div class="v-text-body-1 text-sm-left text-center">
              Расчет интерактивов, интерактивных вставок, рекламных вставок, охват и количество просмотров
            </div>
          </div>
          <v-spacer />
          <v-btn class="mr-4 white--text greenBtn greenBtnBig" @click="openPage('adv')">Реклама</v-btn>
      </div>
    </v-col>
    <v-col class="col-12 col-lg-4">
      <div
          class="productBlock d-flex flex-column flex-sm-row flex-lg-column align-center align-sm-start justify-space-between"
          @mouseover="setActiveProduct(2)"
          @mouseleave="setActiveProduct('')"
          :class="{'active': (activeProduct==2), 'inactive': (activeProduct!=2)}"
      >
          <v-img :src="require('@/assets/about_product_2.png')" width="100%" contain v-if="$vuetify.breakpoint.lgAndUp"/>
          <div>
            <h4 class="v-text-h-4 secondary--text mb-4 text-center text-sm-left text-lg-center">
              <a href="https://lk.mediahills.ru" target="_blank">lk.mediahills.ru</a>
            </h4>
            <div class="v-text-body-1 text-sm-left text-center">
              Измерения телесмотрения для телеканалов и производителей контента
            </div>
          </div>
          <v-spacer />
          <v-btn class="mr-4 white--text greenBtn greenBtnBig" @click="openPage('media')">Медиа</v-btn>
      </div>
    </v-col>
    <v-col class="col-12 col-lg-4">      
      <div
          class="productBlock d-flex flex-column flex-sm-row flex-lg-column align-center align-sm-start justify-space-between"
          @mouseover="setActiveProduct(3)"
          @mouseleave="setActiveProduct('')"
          :class="{'active': (activeProduct==3), 'inactive': (activeProduct!=3)}"
      >
          <v-img :src="require('@/assets/about_product_3.png')" width="100%" contain v-if="$vuetify.breakpoint.lgAndUp"/>
          <div>
            <h4 class="v-text-h-4 secondary--text mb-4 text-center text-sm-left text-lg-center">
              <a href="https://balance.cableman.ru" target="_blank">balance.cableman.ru</a>
            </h4>
            <div class="v-text-body-1 text-sm-left text-center">
              Калькулятор баланса ТВ-пакета
            </div>
          </div>
          <v-spacer />
          <v-btn class="mr-4 white--text greenBtn greenBtnBig" @click="openPage('telecom')">Телеком</v-btn>
      </div>
    </v-col>
  </v-row>
</div>
</template>

<style lang="scss">
.tech-products-row-1 {
  background: #E9F1FF;
}
.tech-products-bg {
  background: url("../assets/ellipse_bgr.svg") #E9F1FF no-repeat center top!important;
  background-size: 100%!important;
  overflow-x: hidden;
}

.productBlock {
  margin-top: 48px;
  box-sizing: border-box;
  padding: 16px 16px 32px;
  gap: 16px;
  height: 430px;
  background: #FFFFFF;
  /* custom/secondary */
  border: 1px solid #0048B9;
  border-radius: 16px;
  @media screen and (max-width: 1264px) {
    min-height: 156px;
    height: auto;
  }

}
.productBlock:hover {
  @media screen and (min-width: 1264px) {
/*     margin-top: 24px;
    margin-bottom: 24px;
    box-shadow: 0px 12px 0px #0048B9; */
  }
}
.productBlock {
  @media screen and (min-width: 1264px) {
    transition: all .4s;
    &.active {
      margin-top: 24px;
      margin-bottom: 24px;
      box-shadow: 0px 12px 0px #0048B9;
    }
    &.inactive {
      margin-top: 48px;
      margin-bottom: 0px;
    }
  }
}

</style>

<script>

import router from "@/router";
export default {
  data: () =>({
    activeProduct: '',
  }),

  methods: {
    setActiveProduct(val) {
      this.activeProduct = val;
    },
    async openPage(page) {
      await router.push(page);
    }
  }
};
</script>