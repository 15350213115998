<template>
<div class="d-flex flex-column">
  <v-row class="pt90 mw1300">
      <v-col class="col-12">
        <h2 class="v-text-h-2 mb-12">Запросить демо системы</h2>
        <div class="v-text-body-2">
          В удобное для вас время наш менеджер покажет интерфейс системы и как в нем работать
        </div>
      </v-col>
  </v-row>   
  <v-row class="pt-12 mw1300 pb90">
      <v-col class="col-12 col-sm-8">
        <div class="v-text-body-1">
          На демонстрации возможностей личного кабинета «МЕДИА ХИЛС» вы сможете оценить в полной мере, подходит ли именно вам этот продукт, а также узнать подробности оформления договора, доступность тех или иных городов и телеканалов и многое другое.
        </div>
        <v-btn color="rgba(0, 149, 44, 1)" class="mt-12 contactBtn" @click="showContactForm('demo_lk')">Запросить демо</v-btn>
      </v-col>
      <v-col class="col-12 col-sm-4">
        <v-img :src="require('@/assets/devices.png')" />
      </v-col>
  </v-row>   
</div>
</template>

<style lang="scss">
</style>
<script>

export default {
    methods: {
    showContactForm(type) {
      this.$store.commit("showContactForm", type);
    }
  }
}
</script>