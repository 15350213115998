<template>
  <div class="tech-row-1">
    <v-row class="pt90 mw1300 mb-12">
      <v-col class="col-12">
        <div class="v-text-body-2 white--text">
          С помощью технологий <span class="yellow--text">Big Data</span> и
          обеспечения доступности телеизмерений всем участникам рынка
          осуществляется формирование современной независимой телеизмерительной
          Панели
        </div>
      </v-col>
    </v-row>
    <v-row class="mw1300">
      <v-col class="col-12 col-sm-4">
        <div class="v-text-body-1 white--text">
          <p>Все города-миллионники,</p>
          <p>города «100 тысяч плюс»</p>
          <p>и города «100 тысяч минус»</p>
        </div>
      </v-col>
      <v-col class="col-12 col-sm-4">
        <div class="v-text-body-1 white--text">
          <p>Более 450 телеканалов и их версий</p>
        </div>
      </v-col>
      <v-col class="col-12 col-sm-4">
        <div class="v-text-body-1 white--text">
          <p>Smart TV, IPTV (ТВ-приставки), планшеты, web, small-screen</p>
        </div>
      </v-col>
    </v-row>
    <v-row class="mw1300 pb90">
      <v-col class="col-12 d-flex justify-center">
        <v-btn color="white" text outlined class="blueBorderBtn2">
          <a href="/about/technology">Как мы работаем</a>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.blueBorderBtn2 a, .greenBtn a {
  color: white !important; text-decoration: none;
}
.tech-row-1 {
  background-color: #002563;
}
</style>

<script>
import router from "@/router";
export default {
  methods: {
    async openPage(page) {
      await router.push(page)
    }
  }

};
</script>
