<template>
  <v-container class="home">
    <title-block />
    <stages-block />
    <tech-block />
    <a name="props" id="props"></a>
    <props-block />
    <contact-block />
    <footer-block />
  </v-container>
</template>
<style>
</style>

<script>
import FooterBlock from "@/components/Footer";
import TitleBlock from "@/components/AboutTitleBlock";
import StagesBlock from "@/components/AboutStagesBlock";
import TechBlock from "@/components/AboutTechBlock";
import PropsBlock from "@/components/AboutPropsBlock";
import ContactBlock from "@/components/TelecomContactBlock";

export default {
  name: "About",
  components: { FooterBlock, TitleBlock, StagesBlock, TechBlock, PropsBlock, ContactBlock },
  data() {
    return {
    };
  },
  mounted() {    
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
</style>
