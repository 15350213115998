<template>
  <div class="lastNews" v-if="lastNews">
    <v-row>
      <v-col class="pl-0 pr-0">
        <h6 class="v-text-h-6">{{lastNews.title}}</h6>
        <div class="v-text-body-1 mt-4">
          {{lastNews.short_content}}
        </div>
        <div class="mt-3 mb-3">
          <span class="dtBlock secondary white--text mr-2">{{drawNewsDate(lastNews)}}</span>
          <span class="yearBlock white">{{drawNewsYear(lastNews)}}</span>
        </div>
        <v-btn color="blue" class="mr-4 white--text" small @click="openPage('news')">
          Читать далее
          <v-icon small>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn v-if="false" color="blue" class="blueBorderBtn" small text outlined @click="openPage('news')">Все новости</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.lastNews {
  width: 528px;
  border-radius: 0px 64px 64px 64px;
  padding: 32px;
  background: linear-gradient(272.93deg, #50D367 0%, #C6FF7E 100%); 
/*   background: #FAFAFA; */
  box-shadow: 30px 4px 46px rgba(0, 255, 163, 0.31); 
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: 40px;
}

.dtBlock {
  font-family: "Noto Sans";
  padding: 5px 12px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}
.yearBlock {
  font-family: "Noto Sans";
  padding: 5px 12px;
  background: #EEEEEE;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}
</style>
<script>

import moment from "moment";
import router from "@/router";

export default {
  name: "LastNews",
  data: () => ({
    name: "",
    email: "",
    phone: "",
    comment: "",
    agree: true
  }),
  methods: {
    async openPage(page) {
      await router.push(page)
    },
    drawNewsDate(news) {
      return moment(news.posted_date, "YYYY-MM-DD HH:mm:ss").locale("ru").format("DD MMMM")
    },
    drawNewsYear(news) {
      return moment(news.posted_date, "YYYY-MM-DD HH:mm:ss").locale("ru").format("Y")
    },
  },
  computed: {
    lastNews() {
      return this.$store.getters.getLastNews;
    }
  }
}
</script>