<template>
  <div class="d-flex flex-column home-title-row-1">
    <v-row class="pt90">
      <v-col class="col-12">
        <h1 class="v-text-h-1 mb-12">
          Создаем <span style="color: rgba(0, 72, 185, 1)">простые</span> и
          <span style="color: rgba(0, 72, 185, 1)">удобные</span> инструменты
          для&nbsp;всех участников медиаиндустрии
        </h1>
        <div class="v-text-body-1 mb-12">
          Независимая исследовательская компания, реализуем проекты в области
          глубокой обработки больших данных с помощью собственных разработок
        </div>
        <div class="mb120 d-flex flex-column flex-sm-row" style="position: relative">
          <v-btn
            color="rgba(0, 149, 44, 1)"
            class="contactBtn mr-4 mb-4 mb-sm-0"
            @click="showContactForm('contact')"
            >Написать нам</v-btn
          >
          <v-btn color="secondary" class="blueBorderBtn mr-4" text outlined @click="makeCall">
            <v-icon small>mdi-phone</v-icon>
            +7 (499) 705-29-33
          </v-btn>
          <last-news v-if="$vuetify.breakpoint.mdAndUp" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.home-title-row-1 {
  background-color: #E9F1FF;
}
</style>
<script>
import lastNews from "@/components/LastNewsBlock";
export default {
  components: { lastNews },
  methods: {
    makeCall() {
      window.open("tel:+74997052933");
    },
    showContactForm(type) {
      this.$store.commit("showContactForm", type);
    }
  }
};
</script>
