<template>
  <v-container class="home">
    <title-block />
    <head-block />
    <recs-block />
    <rec-block />
    <contact-block />
    <footer-block />
  </v-container>
</template>
<style>
</style>
<script>
import FooterBlock from "@/components/Footer";
import TitleBlock from "@/components/TelecomTitleBlock";
import HeadBlock from "@/components/TelecomHeadBlock";
import RecsBlock from "@/components/TelecomRecsBlock";
import RecBlock from "@/components/TelecomRecBlock";
import ContactBlock from "@/components/TelecomContactBlock";

export default {
  name: "Home",
  components: { FooterBlock, TitleBlock, HeadBlock, RecsBlock, RecBlock, ContactBlock },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
</style>
