<template>
  <v-app-bar
    app
    elevation="4"
    :style="headerStyles"
    class="mb-2 pa-0 pl-0 pr-0 header"
    :height="headerHeight"
  >
    <div class="row headerRow">
      <v-img
        @click="toMainPage"
        style="cursor: pointer"
        alt="Mediahills"
        src="../assets/logo.svg"
        max-height="109"
        max-width="165"
        v-if="$vuetify.breakpoint.lgAndUp"
      />
      <v-img
        @click="toMainPage"
        style="cursor: pointer"
        alt="Mediahills"
        src="../assets/logo.svg"
        max-height="59"
        max-width="90"
        v-if="$vuetify.breakpoint.mdAndDown"
      />
      <v-spacer />
      <v-tabs
        slider-color = "rgba(0,0,0,0)"
        color = "orange"
        v-model="activeTab"
        v-if="$vuetify.breakpoint.lgAndUp"
        background-color="transparent"
      >
        <v-tab link to="/media">МЕДИА</v-tab>
        <v-tab link to="/adv">РЕКЛАМА</v-tab>
        <v-tab link to="/telecom">ТЕЛЕКОМ</v-tab>
        <v-tab link to="/about">О&nbsp;НАС</v-tab>
        <v-tab link to="/news">НОВОСТИ</v-tab>
        <v-tab link to="/contacts">КОНТАКТЫ</v-tab>
      </v-tabs>

      <div v-if="$vuetify.breakpoint.mdAndDown">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="ml-2 mr-1"
              color="#002563"
            >
            <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list style="cursor: pointer">
            <v-list-item link to="/media">
              <v-list-item-title>МЕДИА</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/adv">
              <v-list-item-title>РЕКЛАМА</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/telecom">
              <v-list-item-title>ТЕЛЕКОМ</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/about">
              <v-list-item-title>О НАС</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/news">
              <v-list-item-title>НОВОСТИ</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/contacts">
              <v-list-item-title>КОНТАКТЫ</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-btn color="secondary" elevation="0" class="ml-4" @click="openExternalPage('https://lk.mediahills.ru/')">Войти</v-btn>
    </div>
  </v-app-bar>
</template>

<style>
.header {
  margin: 0 auto; 
  max-width: 100%;
}
.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.headerRow .v-slide-group__content {
/*   width: 480px!important; */
  justify-content: flex-end;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #002563!important;
}
.v-tabs {width: auto!important;}
.v-tabs a {	
  font-size:24px;
	font-family:"Noto Sans Display";
	font-weight:700;
	font-style:normal;
	line-height:36px;
	text-decoration:none;
	text-transform:uppercase;
  padding: 0 16px 0 16px;
}
</style>

<script>
import router from "@/router";
export default {
  data: () => ({
    activeTab: null,
    scrollTop: 0,
    pageName: "",
  }),
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollTo(anchor) {
      document.getElementById(anchor).scrollIntoView({ behavior: "smooth" });
    },
    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop;
//      console.log("scroll ", this.scrollTop);
    },
    async toMainPage() {
      await router.push("/");
    },
    openExternalPage(link) {
      window.location.href = link;
    }
  },
  computed: {
    headerHeight() {
      let height = 113;
      if (this.$vuetify.breakpoint.smAndDown) height = 63;
      //console.log(height)
      return height;
    },
    headerStyles() {
      let bgColor = "white";
      if (this.scrollTop == 0) bgColor = "white";
      return {
        "background-color": bgColor + "!important",
        //"filter": "blur(8px)"
      };
    },

  },
  watch: {
  },
};
</script>
