<template>
  <v-app>
    <v-main>
      <app-header />
      <router-view />
      <modal-contact-form v-if="$store.getters.contactForm"/>
      <transition name="formResults">
        <modal-form-result v-if="$store.getters.contactResult!==null"/>
      </transition>
    </v-main>
  </v-app>
</template>

<style>
.formResults-enter-active, .formResults-leave-active {
  transition: opacity .5s;
}
.formResults-enter, .formResults-leave-to {
  opacity: 0;
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import ModalContactForm from "@/components/ModalContactForm";
import ModalFormResult from "@/components/ModalFormResult";

require("@/scss/styles.scss");
export default {
  name: "App",
  components: { AppHeader, ModalContactForm, ModalFormResult },
  mounted() {
    this.$store.commit("hideContactForm");
  },
  methods: {
    check_cookie_name: function (name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return "null";
    },
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@400;500;700&family=Raleway:wght@400;700;800&display=swap');
  .home {min-width: 100%;}
  .row {margin: 0 auto; max-width: 1680px;}
  .container {padding: 0;}
</style>
