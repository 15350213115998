<template>
<div>
  <v-row class="pt90 mw870 mb-8">
      <v-col class="col-12 mt-0 pt-0 mb-0 pb-0">
        <h3 class="v-text-h-3 blue--text d-flex justify-space-between">
          <a href="https://www.gosuslugi.ru/itorgs" target="_blank" class="blue--text">
            Аккредитованный разработчик
          </a>
          <v-icon color="blue" large @click="openExternalPage('https://www.gosuslugi.ru/itorgs')">mdi-open-in-new</v-icon>
        </h3>        
      </v-col>
  </v-row>
  <v-row class="mw870 pb90">
      <v-col class="col-12 mb-8" v-if="false && !$vuetify.breakpoint.smAndUp">
        <v-img :src="require('@/assets/micifr2.png')" />
      </v-col>
      <v-col class="col-12 mt-0 pt-0 mb-4 pb-0 v-text-body-1 d-flex flex-row">
        <p class="mr-8">
          МЕДИА ХИЛС с 15 марта 2019 года состоит в реестре аккредитованных Министерством цифрового развития, 
          связи и массовых коммуникаций Российской Федерации организаций, осуществляющих деятельность в области 
          информационных технологий (с номером записи в реестре 9916). Компания является российским разработчиком 
          <a href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2022616641&TypeFile=html" target="_blank" class="blue--text">ПО «ПРОМЕТЕЙ»</a> 
          и 
          <a href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2022616638&TypeFile=html" target="_blank" class="blue--text">БАЗЫ ДАННЫХ ПО «ПРОМЕТЕЙ»</a>.
        </p>
        <v-img :src="require('@/assets/micifr1.png')" :max-width="154" v-if="false && $vuetify.breakpoint.smAndUp"/>
      </v-col>
  </v-row>
</div>
</template>

<style lang="scss">
</style>
<script>

export default {
  methods: {
    openExternalPage(link) {
      window.location.href = link;
    }
  }
}
</script>