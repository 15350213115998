<template>
  <div class="recs-row">
    <v-row class="pt90 mb-8">
      <v-col class="col-12 mt-0 pt-0 mb-0 pb-0">
        <h2 class="v-text-h-2 white--text text-center">Рекомендательная система MediaHills — это</h2>
      </v-col>
    </v-row>
    <v-row class="pb90 mw1300">
      <v-col class="col-12 col-md-6 col-lg-3 d-flex flex-column align-center">
        <v-img contain :src="require(`@/assets/recs_1.png`)" :max-width="300" />
        <div class="v-text-body-1 white--text text-center">
          Работа с различным типом<br> контента
        </div>
      </v-col>
      <v-col class="col-12 col-md-6 col-lg-3 d-flex flex-column align-center">
        <v-img contain :src="require(`@/assets/recs_2.png`)" :max-width="300" />
        <div class="v-text-body-1 white--text text-center">
          Real-time и исторические данные о телесмотрении 21 млн устройств
        </div>
      </v-col>
      <v-col class="col-12 col-md-6 col-lg-3 d-flex flex-column align-center">
        <v-img contain :src="require(`@/assets/recs_3.png`)" :max-width="300" />
        <div class="v-text-body-1 white--text text-center">
          Соцдем характеристики ваших зрителей
        </div>
      </v-col>
      <v-col class="col-12 col-md-6 col-lg-3 d-flex flex-column align-center">
        <v-img contain :src="require(`@/assets/recs_4.png`)" :max-width="300" />
        <div class="v-text-body-1 white--text text-center">
          Гибкая система управления
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.recs-row {
  background-color: #002563;  
}

</style>
<script>
export default {

};
</script>
