<template>
  <div class="">
    <div style="position: absolute; left: -20000px">
      <table id="propsTable">
        <tr v-for="(prop, i) of propItems" :key="i">
          <td v-if="prop.merge">{{ prop.name }}: {{ prop.value }}</td>
          <td v-if="!prop.merge">{{ prop.value }}</td>
        </tr>
      </table>
    </div>
    <v-row class="pt90 mw1300 pb90">
      <v-col
        class="col-12 d-flex flex-column flex-sm-row align-left align-sm-center mb-12"
      >
        <h2 class="v-text-h-2 mr-12 mb-12 mb-sm-0">Наши реквизиты</h2>
        <v-btn
          color="secondary"
          text
          outlined
          class="blueBorderBtn"
          @click="copyToClipboard('propsTable')"
        >
          <v-icon x-small class="mr-2">mdi-content-copy</v-icon>
          Копировать
        </v-btn>
      </v-col>
      <v-card style="width: 100%">
        <v-card-text class="ml-0 pl-0 mr-0 pr-0 mt-0 pt-0 mb-0 pb-0">
          <div v-for="(prop, i) of propItems" :key="i">
            <div class="propsRow d-flex">
              <div class="propTitle grey--text darken-1">
                {{ prop.name }}
              </div>
              <div class="propValue v-text-body-1">
                {{ prop.value }}
              </div>
            </div>
            <v-divider />
          </div>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<style lang="scss">
.propsRow {
  width: 100%;
}
.propTitle {
  min-width: 240px;
  min-height: 48px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 10px;
  font-size: 12px;
  line-height: 20px;
	@media screen and (max-width: 600px) {
		min-width: 130px;
    max-width: 130px;
	}  
}
.propValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 10px;
  min-height: 48px;
  overflow-x: hidden;
  color: black;
}
</style>

<script>
export default {
  data: () => ({
    propItems: [
      {
        name: "Полное наименование организации",
        value: "Акционерное общество «МЕДИА ХИЛС»",
      },
      {
        name: "Краткое наименование организации",
        value: "АО «МЕДИА ХИЛС»",
      },
      {
        name: "Юридический адрес",
        value:
          "117105, Москва, Варшавское шоссе, д. 1 А, этаж 3, комната 50, офис А3Б",
      },
      {
        name: "Фактический адрес",
        value: "117246, Москва, Научный проезд д. 17",
      },
      {
        name: "ИНН",
        value: "7726385360",
        merge: true
      },
      {
        name: "КПП",
        value: "772601001",
        merge: true
      },
      {
        name: "ОГРН",
        value: "116774681044",
        merge: true
      },
      {
        name: "Банк",
        value: "АО «Райффайзенбанк», г. Москва",
      },
      {
        name: "БИК",
        value: "044525700",
        merge: true
      },
      {
        name: "Корр. счет",
        value: "30101810200000000700",
        merge: true
      },
      {
        name: "Расчетный счет",
        value: "40702810000000025314",
        merge: true
      },
      {
        name: "Генеральный директор",
        value: "Бояринов Андрей Петрович действует на основании Устава",
      },
      {
        name: "Email",
        value: "info@mediahills.ru",
      },
      {
        name: "Web-сайт",
        value: "www.mediahills.ru",
      },
      {
        name: "Телефон",
        value: "+7 (499) 705-29-33",
      },
    ],
  }),
  methods: {
    copyToClipboard(containerid) {
      var range = document.createRange();
      let containerNode = document.getElementById(containerid); //// this part
      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.snackbarCopy = true;
    },
  },
};
</script>
