import BackService from "../services/news.service.js";

const state = {
  news: [],
  yearsData: [],
};
const getters = {
  getNews: (state) => state.news,
  getLastNews: (state) => state.news?.filter(item=>item.posted==1)[0],
  getNewsYears: (state) => state.yearsData,
  getNewsByYear: (state) => (year, posted) => {
    const yearNews = [];
    for (const news of state.news) {
      const newsYear = news.posted_date.substring(0, 4);
      if (!news.type) news.type = "";
      if (newsYear == year) {
        if (posted==1 && news.posted==1) {
          yearNews.push(news);
        }
        if (posted == 0) yearNews.push(news);
      }  
    }
    return yearNews
  }
};

const actions = {
  async GetNews({ commit }) {
    const data = await BackService.getNews({});
    //console.log(data);
    // process data here
    commit("setNews", data.data);
  },
};
const mutations = {
  setNews(state, data) {
    state.news = data;
    const years = [];
    for (const news of state.news) {
      const year = news.posted_date.substring(0, 4);
      if (!years.includes(year)) years.push(year)
    }

    const yearsData = [];
    for (const yearId in years) {
      const yearItem = {
        id: yearId,
        year: years[yearId],
        opened: yearId == 0,
      }
      yearsData.push(yearItem);
    }
    state.yearsData = yearsData;
  },
  toggleYearBlock(state, {yearId}) {
    const year = state.yearsData.find(item=>item.id==yearId)
    if (year) year.opened = !year.opened
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
