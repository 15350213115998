<template>
<div class="d-flex flex-column">
  <div class="media-title-row-1">
    <v-row class="pt90">
        <v-col class="col-12 col-md-6">
          <div class="v-text-body-1 mb-4 text-center text-sm-left" style="color:rgba(0, 37, 99, 0.6)">Для телеканалов и медиаизмерений</div>
          <h1 class="v-text-h-1 mb-12 text-center text-sm-left">Мы делаем телеизмерения<br><span style="color:rgba(0, 72, 185, 1)">доступными</span></h1>
          <v-row class="mb120 d-flex flex-column flex-sm-row">
            <v-btn
              color="rgba(0, 149, 44, 1)"
              class="contactBtn mr-4 mb-4 mb-sm-0"
              @click="showContactForm('contact')"
              >Написать нам</v-btn
            >
            <v-btn color="secondary" text outlined class="blueBorderBtn mr-4" @click="makeCall">
              <v-icon small>mdi-phone</v-icon>
              +7 (499) 705-29-33
            </v-btn>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-6 pb-0 mt-md-0 mt-6">
          <div
            class="chart-container"
            :class="{ chartModal: chartFullscreen }"
            v-if="this.chartData && this.chartDataValid"
            @click="closeFullscreen"
          >
            <highcharts ref="top20Chart" :options="top20Chart" class="top20Chart" v-if="!chartHidden"/>
            <div class="closeChart" v-if="false">×</div>
            <div class="chart-buttons">
              <v-btn x-small outlined fab class="pl-0 pr-0" @click="reloadChart">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn x-small outlined fab class="pl-0 pr-0 ml-4" @click.prevent="toggleFullscreen">
                <v-icon v-if="chartFullscreen">mdi-fullscreen-exit</v-icon>
                <v-icon v-if="!chartFullscreen">mdi-fullscreen</v-icon>
              </v-btn>
            </div>
          </div>
          <v-img v-if="this.chartData && !this.chartDataValid" :src="require('@/assets/chart_image.png')" />
        </v-col>
    </v-row>
  </div>
  <div class="media-title-row-2 mt-0 pt-0">
    <v-row class="pt90 pb90">
      <v-col class="col-12 col-md-6">
          <h2 class="v-text-h-2 mb-12 white--text">Собственная разработка</h2>
          <div class="v-text-body-1 white--text">
            <p>
              ПО «ПРОМЕТЕЙ» И БАЗЫ ДАННЫХ ПО «ПРОМЕТЕЙ» — собственная информационная система MediaHills по онлайн мониторингу и анализу телесмотрения на основе данных о фактическом просмотре ТВ-каналов, получаемых непосредственно с абонентского оборудования, а также из иных источников
            </p>
            <p>
              ПО зарегистрировано на имя Исполнителя в Федеральной службе по интеллектуальной собственности (Свидетельство о регистрации программы для ЭВМ Программное обеспечение «ПРОМЕТЕЙ» № 2022616641 от «15» апреля 2022 г., Свидетельство о регистрации программы для ЭВМ Модуль отчетности ПО «ПРОМЕТЕЙ» № 2022616638 от «15» апреля 2022 г.)
            </p>
          </div>
          <v-btn text color="white" class="mt-8">
            <a class="aLink white--text" target="_blank" href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2022616641&TypeFile=html">
              <v-icon class="mr-3 ml-0">mdi-open-in-new</v-icon>
            </a>  
            <a class="aLink white--text" target="_blank" href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2022616641&TypeFile=html">
              Информация о регистрации ПО
            </a>
          </v-btn>
      </v-col>
      <v-col class="col-12 col-md-6">
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            :src="require('@/assets/our_product_lk.png')"
            :max-height="511"
            contain
          />
      </v-col>
    </v-row>
  </div>
</div>
</template>

<style lang="scss">
.media-title-row-1 {
  background: url("../assets/waves.png") rgba(0, 72, 185, 0.1) no-repeat center bottom!important;
 // background-size: 100%!important;
  overflow-x: hidden;
}

.media-title-row-2 {
  background-color: #0048B9;
}

.chart-container {
  position: relative;
  margin-top: -48px;
}
.chart-container .closeChart {
  position: absolute;
  top: 40px;
  left: 90%;
}
.chart-container .chart-buttons{
  position: absolute;
  top: 72px;
  left: 50%;
  margin-left: -40px;
  cursor: pointer;
	@media screen and (max-width: 600px) {
    top: 112px;
	}  
}
.chartModal  {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
}
.chartModal .top20Chart {
  height: 90%!important;
  width: 90%!important;
  margin: 40px auto;
}

.chartModal .chart-buttons{
  top: 112px;
	@media screen and (max-width: 600px) {
    top: 152px;
	}  
}  

</style>

<script>
import axios from "axios";
import moment from "moment";
import {Chart} from 'highcharts-vue'

export default {
  components: {highcharts: Chart},
  async mounted() {
    await this.reloadChart()
  },
  methods: {
    closeFullscreen(e) {
      if (e && typeof e.target.className==="string" && e.target.className.includes("v-icon")) return;
      if (e && typeof e.target.className==="string" && e.target.className.includes("v-btn")) return;
      if (this.chartFullscreen) this.toggleFullscreen();
    },
    toggleFullscreen() {
      this.chartFullscreen = !this.chartFullscreen;
      setTimeout( () => {this.$refs.top20Chart.chart.reflow();}, 0);
    },
    async reloadChart() {
      const result = await axios.post(`/site/get_chart`);
      if (result.data) {
        this.chartData = result.data;
        this.chartHidden = true
        setTimeout( () => this.chartHidden = false )
      }
    },
    makeCall() {
      window.open("tel:+74997052933");
    },
    showContactForm(type) {
      this.$store.commit("showContactForm", type);
    }
  },
  data: () => ({
    chartData: null,
    chartFullscreen: false,
    heightChart: 343,
    chartHidden: false
  }),
  computed: {
    chartDataValid() {
      if (!this.chartData) return true;
      if (!this.chartData.series || !Array.isArray(this.chartData.series)) return false;
     // console.log(this.chartData.series)
      const seriesSumm = this.chartData.series.reduce((a, b) => a + b, 0);
      if (seriesSumm > 0) return true;
      return false;
    },
    top20Chart() {
      if (!this.chartData) return;
      let pointWidth = 20;
      if (this.chartFullscreen && this.$vuetify.breakpoint.mdAndUp) pointWidth = 30;
      if (this.chartFullscreen && this.$vuetify.breakpoint.lgAndUp) pointWidth = 40;
      let series = [
        {
          pointWidth,
          data: this.chartData.series.map((n) => ({
            y: n,
          })),
          name: "Доля",
        },
      ];

      const chartMax = Math.max(...series[0].data.map((s) => s.y));
      const dt = moment(this.chartData.hday, "YYYY-MM-DD HH:mm:ss").locale("ru").format("LLL");

      return {
        id: "top20",
        credits: {enabled: false},
        chart: {
          type: "column",
          events: {
/*             load: function() {
                  const chart = this;
                  chart.renderer.text("X", chart.plotWidth-20, 85)
                    .css({
                      fontSize: '12px',
                      color: '#666666'
                    })
                    .add();

            } */
          },
          height: this.chartFullscreen?null:343+48,
          backgroundColor: 'white'
        },
        title: { text: "Realtime ТОП 10 телеканалов (GMT +03:00)" },
        subtitle: { text: `по состоянию на ${dt}, аудитория 0+` },
        colors: ["rgb(255, 153, 0)"],
        legend: { enabled: false },
        xAxis: {
          categories: this.chartData.categories,
          labels: {
            formatter: function () {
              let str = this.value;
              //str = `<b style="font-size: 12px;">${str}</b>`;
              //str = `<b style="font-size: 12px;">${str}</b>`;
              return str;
            },
            rotation: -45,
          },
        },
        yAxis: {
          min: 0,
          max: chartMax,
          reversedStacks: false,
          title: { text: "Доля" },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },
        plotOptions: {
          column: {
            colorByPoint: true,
          },
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            point: {
              events: {
              },
            },
            stacking: false,
            dataLabels: { enabled: false },
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
          },
        },
        series: series,
      };
    }
  }
}
</script>