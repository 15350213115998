<template>
<div class="contacts-solutions-row">
  <v-row class="pt-10">
      <v-col class="col-12 mt-0 pt-0">
          <div class="v-text-body-1 mb-4 white--text text-center">
            Предлагаем решения в сферах
          </div>
      </v-col>
  </v-row>
  <v-row class="pb-10 mw800">
      <v-col class="col-12 col-sm-4">
         <v-btn @click="openPage('media')" color="yellow white--text" large block rounded>МЕДИА<v-icon>mdi-arrow-right</v-icon></v-btn>
      </v-col>
      <v-col class="col-12 col-sm-4">
         <v-btn @click="openPage('adv')" color="yellow white--text" large block rounded>РЕКЛАМА<v-icon>mdi-arrow-right</v-icon></v-btn>
      </v-col>
      <v-col class="col-12 col-sm-4">
         <v-btn @click="openPage('telecom')" color="yellow white--text" large block rounded>ТЕЛЕКОМ<v-icon>mdi-arrow-right</v-icon></v-btn>
      </v-col>
  </v-row>

</div>
</template>

<style lang="scss">
  .contacts-solutions-row {
    background-color: #0048B9;
  }
</style>
<script>
import router from "@/router";
export default {
  methods: {
    async openPage(page) {
      await router.push(page)
    }
  }
}
</script>