<template>
  <v-col style="margin:0; padding: 0">
  <div :class="$vuetify.breakpoint.mdAndUp?'methodLinkBlock':'methodLinkBlockSmall'">
    <div class="v-text-body-2 mb-8">
      9 принципов GGTAM
    </div>
    <div class="v-text-body-1 mb-8" v-if="$vuetify.breakpoint.smAndUp">
      9 принципов, которые мы сформулировали на основе международных правил проведения телеизмерений GGTAM
    </div>
    <v-btn class="greenBtn">
      <a href="/about/guidelines">Принципы</a>
    </v-btn>
  </div>
  </v-col>
</template>

<style lang="scss" scoped>
.methodLinkBlock {
  width: 690px;
  max-width: 690px;
  background: #FFFFFF;
  /* custom/secondary */
  border: 1px solid #0048B9;
  border-radius: 24px;
  padding: 16px 32px 32px 32px;
  margin-top: -154px;
	@media screen and (max-width: 960px) {
    width: 480px;
    max-width: 480px;
		}  
}
.methodLinkBlockSmall {
  background-color: #E9F1FF;
  padding:24px;
/*   width: 100%; */
}  
</style>
<script>

import router from "@/router";

export default {
  name: "MethodLinkBlock",
  data: () => ({
  }),
  methods: {
    async openPage(page) {
      await router.push(page)
    },
  },
  computed: {
  }
}
</script>