<template>
  <v-container class="home">
    <title-block />
    <map-block />
    <solutions-block />
    <footer-block />
  </v-container>
</template>
<style>
</style>

<script>
import FooterBlock from "@/components/Footer";
import TitleBlock from "@/components/ContactsTitleBlock";
import MapBlock from "@/components/ContactsMapBlock";
import SolutionsBlock from "@/components/ContactsSolutionsBlock";

export default {
  name: "About",
  components: { FooterBlock, TitleBlock, MapBlock, SolutionsBlock },
  data() {
    return {
    };
  },
  mounted() {    
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
</style>
