<template>
  <div class="d-flex flex-column">
    <div class="about-tech-row-1">
      <v-row class="pt90">
        <v-col class="col-12">
          <div class="v-text-body-1 mb-4 white--text">
            <a
              class="white--text text-decoration-underline"
              @click="openPage('/about')"
              >О нас</a
            >
            <v-icon class="ml-4 mr-4" color="white">mdi-chevron-right</v-icon>
            Как мы работаем
          </div>
          <h1 class="v-text-h-1 mb-12 white--text">
            Технологии и методология
          </h1>
        </v-col>
      </v-row>
      <v-row class="mb120 ml-2 ml-md-auto">
        <div class="v-text-body-1 white--text">
          Cоздание современной телеизмерительной Панели, Программного обеспечения «ПРОМЕТЕЙ» и 
          Базы данных «ПРОМЕТЕЙ» реализуется в соответствии со СТРАТЕГИЕЙ развития информационного 
          общества в Российской Федерации на 2017-2030 годы <a href="http://www.kremlin.ru/acts/bank/41919" target="_blank">Указ Президента Российской Федерации от 09.05.2017 №203</a>
        </div>  
      </v-row>

    </div>
  </div>
</template>

<style lang="scss">
.about-tech-row-1 {
  background-color: #002563;
}
</style>

<script>
import router from "@/router";
export default {
  methods: {
    async openPage(page) {
      await router.push(page)
    }
  }

};
</script>
