<template>
  <div class="d-flex flex-column">
    <div class="telecom-title-row-1">
      <v-row class="pt90">
        <v-col class="col-12">
          <div class="v-text-body-1 mb-4 text-center text-sm-left" style="color: rgba(0, 37, 99, 0.6)">
            Для телекома и операторов связи
          </div>
          <h1 class="v-text-h-1 mb-12 text-center text-sm-left">
            <span style="color:#600DCB">Повышение лояльностии</span><br>и снижение оттока абонентов
          </h1>
          <v-row class="mb120 d-flex flex-column flex-sm-row">
            <v-btn
              color="rgba(0, 149, 44, 1)"
              class="contactBtn mr-4 mb-4 mb-sm-0"
              @click="showContactForm('contact')"
              >Написать нам</v-btn
            >
            <v-btn color="secondary" text outlined class="blueBorderBtn mr-4" @click="makeCall">
              <v-icon small>mdi-phone</v-icon>
              +7 (499) 705-29-33
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="telecom-title-row-2 mt-0 pt90">
      <v-row>
        <v-col class="col-12">
          <h2 class="v-text-h-2 mb-12 white--text text-left">Проверка сбалансированности ТВ-пакета</h2>
        </v-col>
      </v-row>
      <v-row class="pl-2 pr-2 pt-0 mt-0">
        <v-col class="col-12 col-md-8 ml-0 pl-0">
          <div class="v-text-body-1 white--text">
            Система сравнивает контентное наполнение базового пакета оператора с телесмотрением в регионе. Выявляет отклонение ТВ-пакета от востребованности у телезрителей (недостаток или избыток) каналов определенных тематик.
          </div>
          <h4 class="v-text-h-4 white--text mt-12 mb-12">
            Результат проверки
          </h4>
          <div class="v-text-body-1 white--text">
            <ul>
              <li>Общий уровень показывает качество контентного наполнения Базового пакета</li>
              <li>Баланс по категориям демонстрирует недостаток/избыток телеканалов определенных тематик по отношению к телесмотрению в федеральном округе</li>
            </ul>
          </div>  
        </v-col>
        <v-col class="col-12 col-md-4 ml-0 pl-0 text-center">
          <v-img :src="require('@/assets/telecom_balance.png')"  />
          <v-btn class="contactBtn" @click="openExternalPage('https://balance.cableman.ru')">Проверить свой ТВ-пакет</v-btn>
        </v-col>
      </v-row>
      <v-row class="pb90">
        <v-col class="col-12 col-sm-6">
          <div class="v-text-subtitle-1 white--text text-sm-right text-center">
            Совместный проект MediaHills<br>
            с отраслевым изданием «Кабельщик»
          </div>
        </v-col>
        <v-col class="col-12 col-sm-6 d-flex justify-center justify-sm-start">
          <v-img :src="require('@/assets/mh_cableman.svg')" :max-height="58" :max-width="276"/>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss">
.telecom-title-row-1 {
  background: url("../assets/waves3.svg") rgba(96, 13, 203, 0.1) no-repeat
    center bottom !important;
  // background-size: 100%!important;
  overflow-x: hidden;
}

.telecom-title-row-2 {
  background-color: #600DCB;
}


</style>

<script>
export default {
  data: () => ({
    creditId: 1,
    credits: [
      {
        id: 1,
        title: "«Сырые данные» по ца",
        description: "Сессии просмотров по заданной ЦА",
        description2: "(телезритель-город-телеканал-начало-окончание)",
        data_excel: true,
        data_api: true,
      },
      {
        id: 2,
        title: "поминутные аудиторные показатели по ца",
        description:
          "Аудиторные показатели по 250+ городам и всей РФ, города миллионники, 100 тыс. плюс, 100 тыс. минус",
        description2: "",
        data_excel: true,
        data_api: true,
      },
      {
        id: 3,
        title: "post buy analysis",
        description:
          "Аудитория каждого ролика, набранные TRP, охват, частота контактов",
        description2: "",
        data_excel: true,
        data_api: true,
      },
      {
        id: 4,
        title: "brand-lift",
        description:
          "Аналитика по запросам, оценка эффективности проведения РК брендов, лонч продуктов",
        description2: "",
        data_excel: true,
        data_api: false,
      },
      {
        id: 5,
        title: "sales-lift",
        description:
          "Аналитика по запросам, оценка эффективности проведения РК, мэтчинг телекома с ОФД",
        description2: "",
        data_excel: true,
        data_api: false,
      },
      {
        id: 6,
        title: "traffic-lift",
        description: "Аналитика по запросам",
        description2: "",
        data_excel: true,
        data_api: false,
      },
      {
        id: 7,
        title: "ad hoc аналитика",
        description:
          "Аналитика по запросам, оценка эффективности проведения РК",
        description2: "",
        data_excel: true,
        data_api: false,
      },
    ],
  }),
  methods: {
    makeCall() {
      window.open("tel:+74997052933");
    },
    showContactForm(type) {
      this.$store.commit("showContactForm", type);
    },
    openExternalPage(link) {
      window.location.href = link;
    },
    nextCreditCard() {
      this.creditId++
      if (this.creditId>this.credits.length) this.creditId = 1;
    },
    prevCreditCard() {
      this.creditId--
      if (this.creditId<1) this.creditId = this.credits.length;
    }
  },
  computed: {
    currentCredit() {
      const item = this.credits.find(item=>item.id==this.creditId)
      return item;
    }
  },
  watch: {
    creditLink: function () {
      console.log(this.creditLink);
    },
  },
};
</script>
