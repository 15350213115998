<template>
  <div class="modal">
    <div class="modalContactForm elevation-24" :style="`background: ${formColor}`">
      <v-row>
        <v-col class="cols-12 text-right mt-0 pt-0 mr-0 pr-0">
          <v-icon
            x-large
            color="white"
            class="mt-n10"
            style="cursor: pointer"
            @click.prevent="hideContactForm()"
            >mdi-close</v-icon
          >
        </v-col>
        <v-col class="col-12 mb-4 mt-n12">
            <h2 class="v-text-h-2 white--text mb-4 text-center">
              {{formTitle}}
            </h2>
          <div class="v-text-body-1 white--text text-center" v-html="formSubtitle">
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-0 pr-0">
          <span class="yellow--text">*</span> <span class="v-text-body-1 white--text">Имя и фамилия</span>
          <v-text-field
            outlined
            v-model="name"
            ref="name"
            requred
            dense
            background-color="white"
            :rules="nameRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-n10 pl-0 pr-0">
          <span class="yellow--text">*</span> <span class="v-text-body-1 white--text">Эл. почта</span>
          <v-text-field
            outlined
            v-model="email"
            ref="email"
            requred
            dense
            background-color="white"
            :rules="emailRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-n10 pl-0 pr-0">
          <span class="v-text-body-1 white--text">Телефон</span>
          <v-text-field
            outlined
            v-model="phone"
            requred
            dense
            background-color="white"
            @focus="phoneFocus"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-n10 pl-0 pr-0">
          <span class="v-text-body-1 white--text">Комментарий</span>
          <v-textarea outlined v-model="comment" requred dense background-color="white" height="100"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-n10 pl-0 pr-0">
          <v-checkbox 
            class="text--white"
            v-model="agree" 
            color="white" 
            label="Даю согласие на обработку моих персональных данных АО «МЕДИА ХИЛС»" 
          />
        </v-col>
      </v-row>
      <v-btn
        :disabled="!agree && 'disabled'"
        class="mr-4 mb-12 white--text green contactBtn"
        block
        @click="sendForm()"
        >Отправить</v-btn
      >
      <vue-recaptcha 
        ref="recaptcha"
        size="invisible"
        sitekey="6LfbvXIjAAAAAERIFz00pnNaZLzyVQaqPZTcawlx"
        @verify="captchaVerified"
        @expired="onCaptchaExpired"
      />
    </div>
  </div>
</template>

<style lang="scss">
.modalContactForm label, .modalContactForm i {color: white!important}
.modalContactForm {
  overflow: hidden;
  background: #0048B9;
  border-radius: 16px;
  padding: 48px;
  margin: 0 auto;
  width: 80%;
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
	@media screen and (max-width: 600px) {
    zoom: 0.75
  }  
}
.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(218,100%,19%,.46);
}
</style>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  components: { VueRecaptcha },
  name: "ModalContactForm",
  data: () => ({
    requiredFields: ["name", "email"],
    nameRules: [(v) => !!v || "Необходимо заполнить"],
    emailRules: [
      (v) => !!v || "Необходимо заполнить",
      (v) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail невалиден',
    ],
    type: "",
    name: "",
    email: "",
    phone: "",
    comment: "",
    agree: false
  }),
  computed: {
    formColor() {
      switch (this.$store.getters.contactType) {
        case "contact": return "#0048B9"; //secondary
        case "demo_lk": return "#0048B9"; //secondary
        case "demo_estim": return "#0048B9"; //secondary
        default: return "#004916"; //new green
      }
    },
    formTitle() {
      switch (this.$store.getters.contactType) {
        case "contact": return "Задайте нам вопрос";
        case "demo_lk": return "Запланируйте демонстрацию";
        case "demo_estim": return "Запланируйте демонстрацию";
        default: return this.$store.getters.contactType;
      }
    },
    formSubtitle() {
      switch (this.$store.getters.contactType) {
        case "contact": return "Заполните форму ниже, чтобы отправить запрос на консультацию в MediaHills";
        case "demo_lk": return "Форма для отправки заявки на демо продукта<br>лк «МЕДИА ХИЛС»";
        case "demo_estim": return "Форма для отправки заявки на демо продукта<br>ЭСТИМ: Интерактивы";
        default: return "Заполните форму и получите консультацию";
      }
    }
  },
  methods: {
    phoneFocus() {
      if (!this.phone) this.phone = "+7";
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset();
    },
    hideContactForm(e) {
      //console.log("hide")
      if (e && e.target.className=="modal" || !e) {
        this.$store.commit("hideContactForm");
      }
    },
    validateForm() {
      if (!this.agree) return false;
      let formHasErrors = false;
      for (const field of this.requiredFields) {
        const r = this.$refs[field].validate();
        if (!r) formHasErrors = true;
      }
      return !formHasErrors;
    },
    captchaVerified(recaptchaToken) {
      console.log(recaptchaToken)
     // if (!this.validateForm()) return;
      const data = {
        type: this.$store.getters.contactType,
        title: `${this.formTitle} ${this.formSubtitle}`,
        name: this.name,
        email: this.email,
        phone: this.phone,
        comment: this.comment,
        recaptchaToken,
      }
      this.$store.dispatch("sendForm", data);
      this.$store.commit("hideContactForm");
    },
    sendForm() {
      console.log("send " + this.type);
      if (!this.validateForm()) return;
      this.$refs.recaptcha.execute();
    }
  }
}
</script>