<template>
  <div class="">
    <v-row class="pt78 mb-12">
      <v-col class="col-12">
        <h1 class="v-text-h-1">Контакты</h1>
      </v-col>
    </v-row>
    <v-row class="mb-10">
      <v-col class="col-12 col-md-6">
        <div class="mb-8">
          <v-icon color="indigo" class="mr-2 mt-n1">mdi-phone</v-icon>
          <a class="v-text-h-4 indigo--text" href="tel:+74957052933"
            >8 (499) 705-29-33</a
          >
        </div>
        <div class="mb-8">
          <v-icon color="indigo" class="mr-2 mt-n1" @click="openPage('about#props')"
            >mdi-book-open-variant</v-icon
          >
          <a class="v-text-h-5 indigo--text text-decoration-underline" @click="openPage('about#props')"
            >Реквизиты</a
          >
        </div>
      </v-col>
      <v-col class="col-12 col-md-6">
        <div class="mb-8">
          <v-icon color="indigo" class="mr-2 mt-n1">mdi-email</v-icon>
          <a class="v-text-h-5 indigo--text" href="mailto:info@mediahills.ru"
            >info@mediahills.ru</a
          >
        </div>
        <div class="v-text-body-1 mt-8 d-flex align-center">
          <v-icon color="secondary" class="mr-2" x-large style="margin-left:-6px;">mdi-map-marker</v-icon>
          <div>117246, город Москва, Научный проезд, дом 17 Бизнес-Центр «9 Акров»</div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss"></style>

<script>
import router from "@/router";
export default {
  methods: {
    async openPage(page) {
      await router.push(page);
    },
  },
};
</script>
