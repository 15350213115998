<template>
  <div class="adv-pixel-row">
    <v-row class="pt90 mb-8">
      <v-col class="col-12 mt-0 pt-0 mb-0 pb-0">
        <h2 class="v-text-h-2">Взаимосвязь просмотра ТВ с интернет-поведением и реальными действиями потребителей на сайтах (клики)</h2>
      </v-col>
    </v-row>
    <v-row class="pb90">
      <div class="d-flex flex-row justify-space-between">
        <div class="pl-4">
          <div class="v-text-body-1">
            MediaHills разработал решения, позволяющие объединить данные о фактах и частоте просмотров ТВ-рекламы и интернет-рекламы конкретными пользователями с их покупками и данными CRM продавца.
          </div>
          <h4 class="v-text-h-4 mt-12 mb-12">
            Для целей проекта по мониторингу используется <span class="secondary--text">пиксель</span>, который позволяет отследить:
          </h4>
          <div class="v-text-body-1 mb-12">
              <p>➔ Идентификатор пользователя на сайте (может использоваться cookie, Yandex ID, Google ID или любой другой)</p>
              <p>➔ Страница сайта или ее идентификатор</p>
              <p>➔ Название просматриваемого товара / услуги</p>
              <p>➔ Название / идентификатор нажатой кнопки или другого действия</p>
          </div>
          <v-row class="d-flex flex-column flex-sm-row">
            <v-btn
              color="rgba(0, 149, 44, 1)"
              class="contactBtn mr-4 mb-4 mb-sm-0"
              @click="showContactForm('Консультация по пикселю')"
              >Получить консультацию</v-btn
            >
            <v-btn color="secondary" text outlined class="blueBorderBtn mr-4"  @click="makeCall">
              <v-icon small>mdi-phone</v-icon>
              +7 (499) 705-29-33
            </v-btn>
          </v-row>  
        </div>
        <div v-if="$vuetify.breakpoint.lgAndUp" style="width:288px">
          <v-img :src="require('@/assets/magnifier.png')"  />
        </div>
      </div>
    </v-row>    
  </div>
</template>

<style lang="scss">
  .adv-pixel-row {
    background-color: #E8F5E9;
  }
</style>

<script>
export default {
    methods: {
      makeCall() {
        window.open("tel:+74997052933");
      },
      showContactForm(type) {
        this.$store.commit("showContactForm", type);
    }
  }
};
</script>
