<template>
<div class="d-flex flex-column">
  <div class="about-title-row-1">
    <v-row class="mb-4 pt90">
        <v-col class="col-12 col-md-6">
          <div class="v-text-body-1 text-center text-sm-left" style="color: rgba(0, 37, 99, 0.6)">О нас</div>
        </v-col>
    </v-row>
    <v-row class="pb90">
        <v-col class="col-12 col-md-6">
          <h1 class="v-text-h-1 mb-12 text-center text-sm-left">АО «МЕДИА ХИЛС»</h1>
          <div class="v-text-body-1 mb-12">
            <p>
              MediaHills работает с 2016 года и входит в группу компаний Mediascope. MediaHills является российской исследовательской компанией, которая реализует проекты в области глубокой обработкой данных на основе собственных IT-разработок. Располагает крупнейшей в Европе открытой системой измерения телеаудитории, основанной на данных, собранных по технологии RPD (Return Path Data - сбор данных о телесмотрении с клиентских устройств).
            </p>
            <ul>
              <li>независимая исследовательская компания</li>
              <li>аккредитованный IT-разработчик</li>
            </ul>
          </div>
          <v-row class="d-flex flex-column flex-sm-row">
            <v-btn
              color="rgba(0, 149, 44, 1)"
              class="contactBtn mr-4 mb-4 mb-sm-0"
              @click="showContactForm('contact')"
              >Написать нам</v-btn
            >
            <v-btn color="secondary" text outlined class="blueBorderBtn mr-4" @click="makeCall">
              <v-icon small>mdi-phone</v-icon>
              +7 (499) 705-29-33
            </v-btn>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-6">
          <img v-if="false" :src="require('@/assets/micifr2.png')" width="212" height="46"/>
          <div class="v-text-body-1 mt-8">
            MediaHills с 15 марта 2019 года состоит в реестре аккредитованных Министерством цифрового развития, связи и массовых коммуникаций Российской Федерации организаций, осуществляющих деятельность в области информационных технологий (с номером записи в реестре 9916). Компания является российским разработчиком ПО «ПРОМЕТЕЙ» и БАЗЫ ДАННЫХ ПО «ПРОМЕТЕЙ».
          </div>  
        </v-col>
    </v-row>   
  </div>
</div>
</template>

<style lang="scss">
.about-title-row-1 {
  background-color: #E9F1FF;
}
</style>

<script>

export default {
   methods: {
    makeCall() {
      window.open("tel:+74997052933");
    },
    showContactForm(type) {
      this.$store.commit("showContactForm", type);
    }
  }
}
</script>
