import BackService from "../services/news.service.js";

const state = {
  contactType: "",
  contactResult: null,
  contactForm: false,
};
const getters = {
  contactForm: (state) => state.contactForm,
  contactType: (state) => state.contactType,
  contactResult: (state) => state.contactResult,
};

const actions = {
  async sendForm({ commit }, params) {
    const data = await BackService.sendContactForm({params});
    if (!("success" in data)) data.success = false;
    const timeout = data.success ? 2400 : 4600;
    commit("setResult", data.success);
    setTimeout(() => { commit("setResult", null); }, timeout )
  },
};
const mutations = {
  setResult(state, result) {
    state.contactResult = result;
  },
  showContactForm(state, contactType) {
    state.contactForm = true;
    state.contactType = contactType;
  },
  hideContactForm(state) {
    state.contactForm = false;
    state.contactFormData = {};
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
