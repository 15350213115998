<template>
  <v-container class="home">
    <title-block />
    <solutions-block />
    <data-block />
    <develop-block />
    <offer-block />
    <footer-block />
  </v-container>
</template>
<style>
</style>

<script>
import FooterBlock from "@/components/Footer";
import TitleBlock from "@/components/HomeTitleBlock";
import SolutionsBlock from "@/components/HomeSolutionsBlock";
import DevelopBlock from "@/components/HomeDevelopBlock";
import DataBlock from "@/components/HomeDataBlock";
import OfferBlock from "@/components/HomeOfferBlock";


export default {
  name: "Home",
  components: { FooterBlock, TitleBlock, SolutionsBlock, DataBlock, DevelopBlock, OfferBlock },
  data() {
    return {
    };
  },
  mounted() {
    this.$store.dispatch("GetNews");
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
</style>
