<template>
  <div class="contactForm">
    <v-row>
      <v-col class="pl-0 pr-0">
        <span class="yellow--text">*</span> <span class="v-text-body-1 white--text">Имя и фамилия</span>
          <v-text-field
            outlined
            v-model="name1"
            ref="name1"
            requred
            dense
            background-color="white"
            :rules="nameRules"
          />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-n10 pl-0 pr-0">
        <span class="yellow--text">*</span> <span class="v-text-body-1 white--text">Эл. почта</span>
          <v-text-field
            outlined
            v-model="email1"
            ref="email1"
            requred
            dense
            background-color="white"
            :rules="emailRules"
          />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-n10 pl-0 pr-0">
        <span class="v-text-body-1 white--text">Телефон</span>
        <v-text-field
          outlined
          v-model="phone"
          requred
          dense
          background-color="white"
          @focus="phoneFocus"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-n10 pl-0 pr-0">
        <span class="v-text-body-1 white--text">Комментарий</span>
        <v-textarea outlined v-model="comment" requred dense background-color="white" height="100"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-n10 pl-0 pr-0">
        <v-checkbox 
          class="text--white"
          v-model="agree" 
          color="white" 
          label="Даю согласие на обработку моих персональных данных АО «МЕДИА ХИЛС» в соответствии с политикой конфиденциальности" 
        />
      </v-col>
    </v-row>
    <v-btn
      :disabled="!agree && 'disabled'"
      color="rgba(0, 149, 44, 1)"
      class="mr-4 mb-12 contactBtn"
      block
      @click="sendForm"
      >Отправить</v-btn
    >
    <vue-recaptcha 
      ref="recaptcha1"
      size="invisible"
      sitekey="6LfbvXIjAAAAAERIFz00pnNaZLzyVQaqPZTcawlx"
      @verify="captchaVerified"
      @expired="onCaptchaExpired"
    /> 
  </div>
</template>

<style lang="scss">
.contactForm label, .contactForm i {color: white!important}
.contactForm {
  background: #0048B9;
  border-radius: 0px 64px 64px 64px;
  padding: 48px;
  overflow-x: hidden;
  transform: translate(0%, 0%);
}
</style>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: "ContactForm",
  components: { VueRecaptcha },
  data: () => ({
    requiredFields: ["name1", "email1"],
    nameRules: [(v) => !!v || "Необходимо заполнить"],
    emailRules: [
      (v) => !!v || "Необходимо заполнить",
      (v) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail невалиден',
    ],
    name1: "",
    email1: "",
    phone: "",
    comment: "",
    agree: false
  }),
  methods: {
    phoneFocus() {
      if (!this.phone) this.phone = "+7";
    },
    onCaptchaExpired () {
      this.$refs.recaptcha1.reset();
    },
    validateForm() {
      if (!this.agree) return false;
      let formHasErrors = false;
      for (const field of this.requiredFields) {
        const r = this.$refs[field].validate();
        if (!r) formHasErrors = true;
      }
      return !formHasErrors;
    },
/*     sendForm() {
      if (!this.validateForm()) return;
      const data = {
        type: "contact",
        title: `Запрос с главной страницы сайта`,
        name: this.name1,
        email: this.email1,
        phone: this.phone,
        comment: this.comment,
      }
      this.name1 = "";
      this.email1 = "";
      this.phone = "";
      this.comment = "";
      this.$store.dispatch("sendForm", data);
    }, */
    sendForm() {
      console.log("send " + this.type);
      if (!this.validateForm()) return;
      this.$refs.recaptcha1.execute();
    },
    captchaVerified(recaptchaToken) {
      console.log(recaptchaToken)
      // if (!this.validateForm()) return;
      const data = {
        type: "contact",
        title: `Запрос с главной страницы сайта`,
        name: this.name1,
        email: this.email1,
        phone: this.phone,
        comment: this.comment,
        recaptchaToken,
      };
      this.name1 = "";
      this.email1 = "";
      this.phone = "";
      this.comment = "";
      this.$store.dispatch("sendForm", data);
    },
  },
}
</script>