<template>
<div class="d-flex flex-column">
  <div class="home-subtitle-row-1">
    <v-row class="pt90">
        <v-col class="col-12 col-md-6">          
          <h2 class="v-text-h-2 mb-12">Детальные измерения</h2>
          <div class="v-text-body-1">
            Система позволяет в реальном времени оценить основные аудиторные показатели всех телеканалов
            <ul class="pt-4">
              <li>Доля (Share)</li>
              <li>Рейтинг (TVR)</li>
              <li>Охват (Reach)</li>
              <li>Аудитория (Audience)</li>
            </ul>
          </div>
          <v-img
            :src="chartImage"
            @mouseenter="chartImage = chartImageSrcAnim"
            @mouseleave="chartImage = chartImageSrc"
          />
        </v-col>
        <v-col class="col-12 col-md-6">
          <v-img
            :src="citiesImage"
            @mouseenter="citiesImage = citiesImageSrcAnim"
            @mouseleave="citiesImage = citiesImageSrc"
          />
          <h2 class="v-text-h-2 mt-8">240+ городов РФ</h2>
          <div class="v-text-body-1 mt-4">
            В большинстве регионов измерения доступны для основных городов, включая столицу. Даже в небольших городах телеизмерительная панель охватывает тысячи домохозяйств
          </div>  
        </v-col>
    </v-row>   
  </div>
</div>
</template>

<style lang="scss">
</style>
<script>

export default {
  mounted() {

  },
  data: () => ({
    citiesImageSrc: require('@/assets/cities.png'),
    citiesImageSrcAnim: require('@/assets/cities_anim.gif'),
    citiesImage: require('@/assets/cities.png'),
    chartImageSrc: require('@/assets/chart2_image.png'),
    chartImageSrcAnim: require('@/assets/chart2_image_anim.gif'),
    chartImage: require('@/assets/chart2_image.png'),
  })
}
</script>