<template>
  <div class="modal" @click.prevent="hideContactForm">
    <div class="modalContactResult elevation-24" :style="`background: ${formColor}`">
      <v-row>
        <v-col class="col-12 mb-4">
          <h2 class="v-text-h-2 white--text mb-4 text-center">
            {{formTitle}}
          </h2>
          <div class="v-text-body-1 white--text text-center" v-html="formSubtitle">
          </div>
        </v-col>
      </v-row>  
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modalContactResult label, .modalContactResult i {color: white!important}
.modalContactResult {
  background: #0048B9;
  border-radius: 16px;
  padding: 48px;
  margin: 0 auto;
  width: 80%;
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(218,100%,19%,.46)
}
</style>
<script>

export default {
  name: "ModalContactResult",
  data: () => ({
  }),
  computed: {
    formColor() {
      if (this.$store.getters.contactResult===true) {
        return "#004916";
      }  
      return "#FF5722";
    },
    formTitle() {
      if (this.$store.getters.contactResult===true) {
        return "Спасибо, все успешно";
      }
      return "Форма не отправлена"
    },
    formSubtitle() {
      if (this.$store.getters.contactResult===true) {
        return "Мы свяжемся с вами в ближайшее время и ответим на все вопросы."
      }
      return "Что-то пошло не так.<br>Пожалуйста, заполните и отправьте форму снова.";
    }
  },
  methods: {
    hideContactForm(e) {
      //console.log("hide")
      if (e.target.className=="modal") {
        this.$store.commit("hideContactForm");
      }
    },
    validateForm() {
      if (!this.agree) return false;
      let formHasErrors = false;
      for (const field of this.requiredFields) {
        if (!this[field]) formHasErrors = true;
        this.$refs[field].validate(true);
      }
      return !formHasErrors;
    },
    sendForm() {
      console.log("send " + this.type);
      if (!this.validateForm()) return;
      const data = {
        type: this.$store.getters.contactType,
        title: `${this.formTitle} ${this.formSubtitle}`,
        name: this.name,
        email: this.email,
        phone: this.phone,
        comment: this.comment,
      }
      this.$store.dispatch("sendForm", data);
      this.$store.commit("hideContactForm");
    }
  }
}
</script>