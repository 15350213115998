<template>
  <v-container class="home">
    <title-block />
    <what-block />
    <products-block />
    <footer-block />
  </v-container>
</template>
<style>
</style>

<script>
import FooterBlock from "@/components/Footer";
import TitleBlock from "@/components/AboutTechTitleBlock";
import WhatBlock from "@/components/AboutTechWhatBlock";
import ProductsBlock from "@/components/AboutTechProductsBlock";

export default {
  name: "AboutTech",
  components: { FooterBlock, TitleBlock, WhatBlock, ProductsBlock },
  data() {
    return {
    };
  },
  mounted() {    
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
</style>
