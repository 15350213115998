<template>
  <div>
    <v-row class="pt90 mb-8">
      <v-col class="col-12 mt-0 pt-0 mb-0 pb-0">
        <h2 class="v-text-h-2">Рекомендательная система</h2>
      </v-col>
    </v-row>
    <v-row class="pb90">
      <v-col class="col-12 col-md-6 mt-0 pt-0 mb-4 pb-0">
        <div class="v-text-body-1">
          <p>MediaHills разработал и запустил в коммерческую эксплуатацию собственную рекомендательную систему, которая выявляет профиль телезрителя и предлагает ему персонализированный контент.</p>
          <p>Система позволяет:</p>
          <p>
            <ul>
              <li>повысить продолжительность телесмотрения</li>
              <li>увеличить объем потребляемого VOD контента</li>
              <li>максимально долго удержать внимание абонента в приложении оператора</li>
              <li>повысить продажи платного контента, дополнительных ТВ-пакетов или платных телеканалов</li>
            </ul>
          </p>
          <p>
            <a href="/documents/MediaHills - Рекомендательная система.pdf" download>Скачать презентацию</a>
          </p>
        </div>
      </v-col>
      <v-col class="col-12 col-md-6 mt-0 pt-0 mb-4 pb-0 text-center d-flex flex-column">
        <h6 class="v-text-h-6 mb-4">Профиль телезрителя</h6>                          
        <div class="d-flex justify-center mb-4">
          <div class="headsContainer">
            <v-img class="headImage" :src="require(`@/assets/${heads[currentHead]}`)" :width="420" :height="300" contain/>

            <div v-for="(item,id) in larvas[currentHead]" :key="id">
              <v-menu offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <div class="headLarva"
                    :style="`top: ${item.top}px; left: ${item.left}px;`"
                    v-bind="attrs"
                    v-on="on">
                  </div>
                </template>
                <div class="headPopup white--text">
                  <div class="v-text-body-1 mb-2" v-html="item.text" />
                  <img contain :src="require(`@/assets/${item.image}`)" />
                </div>
              </v-menu>
            </div>

          </div>
        </div>  
        <div>
          <img class="smallHead mr-2" :class="currentHead==0 && `active`" :src="require(`@/assets/${heads[0]}`)" @click="currentHead=0">
          <img class="smallHead mr-2" :class="currentHead==1 && `active`" :src="require(`@/assets/${heads[1]}`)" @click="currentHead=1">
          <img class="smallHead mr-2" :class="currentHead==2 && `active`" :src="require(`@/assets/${heads[2]}`)" @click="currentHead=2">
        </div>        
      </v-col>      
    </v-row>
  </div>
</template>

<style lang="scss">
  .smallHead {
    width: 68px !important;
    height: 48px !important;
    cursor: pointer;    
  }
  .smallHead.active {
    border: 4px solid #C6FF7E;
  }
  .headsContainer {
    position: relative;
    width: 420px;
    height: 300px;
  }
  .headImage {
    position: absolute;
    top: 0;
    left: 0;
  }
  .headLarva {
    opacity: .5;
    position: absolute;
    background: #FFFFFF;
    border: 6px solid #0048B9;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor:pointer;
  }
  .headLarva::before {
    content: '';
    padding: 25px 40px 25px 40px;
    margin-left: -25px;
  }
  .headLarva:hover {
    opacity: 1;
  }
  .headPopup {
    background-color: #0048B9;
    border-radius: 8px;
    padding: 8px;
  }
</style>
<script>
export default {
  data: () =>({
    currentHead: 0,
    heads: [
      "heads/viewer_profile_gallery_wife.png",
      "heads/viewer_profile_gallery_husband.png",
      "heads/viewer_profile_gallery_daugther.png",
    ],
    larvas: [
      [ {
        text: "Смотрит кулинарию по<br> воскресеньям",
        image: "heads/w1.png",
        top: 97+26,
        left: 26+44,
      }, {
        text: "Нравится смотреть<br> передачи о природе",
        image: "heads/w2.png",
        top: 20+26,
        left: 128+44,
      }, {
        text: "Любит слушать оперу",
        image: "heads/w3.png",
        top: 133+26,
        left: 196+44,
      }, {
        text: "Имеет высокий доход",
        image: "heads/w4.png",
        top: 203+26,
        left: 26+44,
      } 
    ],
    [ {
          text: "Нравятся передачи о<br> спорте",
          image: "heads/m1.png",
          top: 105+26,
          left: 33+44,
        }, {
          text: "Слушает музыку 80-х и<br> 90-х",
          image: "heads/m4.png",
          top: 14+26,
          left: 111+44,
        }, {
          text: "Любит боевики",
          image: "heads/m3.png",
          top: 133+26,
          left: 196+44,
        }, {
          text: "Имеет средний доход",
          image: "heads/m22.png",
          top: 201+26,
          left: 66+44,
        } 
    ],
    [ {
          text: "Мультики!",
          image: "heads/d11.png",
          top: 101+26,
          left: 77+44,
        }, {
          text: "Нравится смотреть<br> передачи о природе",
          image: "heads/d2.png",
          top: 32+26,
          left: 106+44,
        }, {
          text: "Любит путешествия",
          image: "heads/d3.png",
          top: 90+26,
          left: 157+44,
        }, 
    ],         
  ]  
  })
};
</script>
