<template>
  <div class="">
    <v-row class="pt90 pb90">
      <v-col class="col-12">
        <h4 class="v-text-h-4 mb-4">
          Максимальный охват
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>Стремиться охватить все населенные пункты и социальные слои России, а также представлять данные со всех доступных на данный момент типов конечных устройств (телевизионные приставки, SMART TV, ПК, планшеты, смартфоны и любые новые типы устройств).</p>
        </div>
        <h4 class="v-text-h-4 mb-4">
          Открытость
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>Все аспекты применяемых исследовательских методик должны быть открыты для ознакомления, применяемые технологии — максимально прозрачными (включая результаты установочных исследований). Лишь в этом случае возможно полное понимание у участников рынка особенностей системы сбора и обработки данных и компромиссных решений, принимаемых при отсутствии идеального решения.</p>
        </div>
        <h4 class="v-text-h-4 mb-4">
          Ответственность
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>Максимально ответственное отношение к опубликованию полученных данных. Данные публикуются только после проведения внутренней альтернативной проверки валидности измерений и получения положительного результата внешнего аудита Большой Системы Телеизмерений.</p>
        </div>
        <h4 class="v-text-h-4 mb-4">
          Равные условия доступа к результатам исследования
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>В интересах честной конкурентной борьбы мы должны предоставлять одинаковые условия и единые расценки за предоставление информации всем группам пользователей, что способствует:</p>
          <p>Открытости самой системы измерения</p>
          <p>Равенству условий для продавцов и покупателей эфирного времени</p>
          <p>Максимально полному использованию полезной информации</p>
        </div>
        <h4 class="v-text-h-4 mb-4">
          Конфиденциальность
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>Информация о поставщиках данных (операторах связи) и их абонентах должна быть конфиденциальной. Конфиденциальность этой информации очень важна для функционирования независимой системы и предотвращения смещения результатов. Внутри MediaHills информация должна быть доступна только тем сотрудникам, которым это необходимо знать по роду деятельности. Другие организации, которым доступ к данным должен быть обеспечен в соответствии с законодательством или для подтверждения работоспособности и качества Системы (такие, как аудиторы) должны быть связаны соглашениями о неразглашении.</p>
        </div>
        <h4 class="v-text-h-4 mb-4">
          Научное обоснование применяемых технологий
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>Применяемые исследовательские методики должны быть только научно обоснованы и апробированы на практике. Это важно для обеспечения валидности (достоверности) и надежности системы.</p>
        </div>
        <h4 class="v-text-h-4 mb-4">
          Консультации со всеми участниками телевизионного рынка
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>Обеспечивать тесное консультативное взаимодействие со всеми пользователями информации о телеаудитории. Соответствие высказанным и перспективным требованиям пользователей к Системе является ключевой задачей.</p>
        </div>
        <h4 class="v-text-h-4 mb-4">
          Постоянный контроль качества
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>На любом этапе, от работы с респондентами (при установочных исследованиях) и сбора данных, до обработки и доставки результатов исследования, необходим тщательный и систематический контроль качества.</p>
        </div>
        <h4 class="v-text-h-4 mb-4">
          Возможность методологического экспериментирования
        </h4>
        <div class="v-text-body-1 mb-12">
          <p>Мы находимся в состоянии постоянного поиска новых методик, позволяющих все более качественно проводить измерение аудитории, прогнозировать ее поведение. Особое внимание уделяется изучению неточностей прогнозирования и особенностей технических систем измерения. Методологии и результаты проведенных исследований документируются и находятся в открытом доступе для всех категорий пользователей.</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
</style>

<script>
import router from "@/router";
export default {
  methods: {
    async openPage(page) {
      await router.push(page)
    }
  }

};
</script>
