<template>
  <v-container class="home">
    <title-block />
    <data-source-block />
    <pixel-block />
    <footer-block />
  </v-container>
</template>
<style>
</style>
<script>
import FooterBlock from "@/components/Footer";
import TitleBlock from "@/components/AdvTitleBlock";
import DataSourceBlock from "@/components/AdvDataSourceBlock";
import PixelBlock from "@/components/AdvPixelBlock";

export default {
  name: "Home",
  components: { FooterBlock, TitleBlock, DataSourceBlock, PixelBlock },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
</style>
