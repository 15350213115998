<template>
  <div class="contacts-map-row">
    <yandex-map
      v-if="!mapHidden"
      zoom="15"
      style="width: 100%; max-width: 100%; height: 460px"
      :coords="coords"
      :scroll-zoom="false"
    >
      <ymap-marker
        marker-id="1"
        :coords="coords"
        :hint-content="hintContent"
        :balloon-template="hintContent"
      ></ymap-marker>
    </yandex-map>
  </div>
</template>

<style lang="scss">
 .contacts-map-row {
/*     background-color: #50D367; */
 }
 </style>
<script>
import router from "@/router";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
export default {  
  components: { yandexMap, ymapMarker },
  mounted() {
  },
  data: () => ({
    mapHidden: false,
    coords: [55.654137, 37.555876],
    hintContent: "Москва, Научный проезд, дом 17",
  }),
  methods: {
    async openPage(page) {
      await router.push(page);
    },
  },
};
</script>
